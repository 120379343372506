import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { Dialog } from "@headlessui/react";
import { createClient } from "../client.service";

const ClientModal = ({ isOpen, onClose, onClientCreated }) => {
  const [clientName, setClientName] = useState("");
  const modalRef = useRef(null);

  const handleSubmit = async () => {
    try {
      await createClient(clientName);
      setClientName("");
      onClientCreated();
      onClose();
    } catch (error) {
      console.error("Error creating client:", error);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      className="fixed z-10 inset-0 overflow-y-auto"
    >
      <div className="flex items-center justify-center min-h-screen">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
        <div className="relative bg-white rounded-lg p-6 max-w-sm w-full mx-4">
          <Dialog.Title className="text-lg font-medium mb-4">
            Add New Client
          </Dialog.Title>
          <input
            type="text"
            value={clientName}
            onChange={(e) => setClientName(e.target.value)}
            className="w-full p-2 border rounded mb-4"
            placeholder="Client Name"
          />
          <div className="flex justify-end gap-2">
            <CancelButton onClick={onClose}>Cancel</CancelButton>
            <AddButton onClick={handleSubmit} disabled={!clientName.trim()}>
              Add Client
            </AddButton>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

const CancelButton = styled.button`
  padding: 8px 16px;
  color: #4b5563;
  background-color: #f3f4f6;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #e5e7eb;
  }
`;

const AddButton = styled.button`
  padding: 8px 16px;
  color: white;
  background-color: #4c1d95;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;

  &:disabled {
    background-color: #a78bda;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background-color: #3700b3;
  }
`;

export default ClientModal;
