import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Modal from "../components/Modal";
import { inject, observer } from "mobx-react";

const TIMEOUT_DURATION = 30 * 60 * 1000; // 30 minutes
const WARNING_DURATION = 1 * 60 * 1000; // 1 minute warning before logout

// The actual hook
const useInactivityTimerHook = (store, navixScribeV2Store) => {
  const history = useHistory();
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    let timeoutId;
    let warningTimeoutId;

    const resetTimer = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      if (warningTimeoutId) {
        clearTimeout(warningTimeoutId);
      }

      // Only set timeouts if logged in and not recording
      if (store.isLoggedIn && !navixScribeV2Store?.isRecording) {
        // Set warning timeout
        warningTimeoutId = setTimeout(() => {
          setShowWarning(true);
        }, TIMEOUT_DURATION - WARNING_DURATION);

        // Set actual logout timeout
        timeoutId = setTimeout(() => {
          setShowWarning(false);
          store.handleLogout();
          window.location.href = "/login";
        }, TIMEOUT_DURATION);
      }
    };

    const events = [
      "mousedown",
      "mousemove",
      "keypress",
      "scroll",
      "touchstart",
    ];

    events.forEach((event) => {
      document.addEventListener(event, resetTimer);
    });

    resetTimer();

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      if (warningTimeoutId) {
        clearTimeout(warningTimeoutId);
      }
      events.forEach((event) => {
        document.removeEventListener(event, resetTimer);
      });
    };
  }, [store.isLoggedIn, history, store, navixScribeV2Store?.isRecording]);

  const handleStayActive = () => {
    setShowWarning(false);
    // This will trigger resetTimer through a mousemove event
    const event = new MouseEvent("mousemove");
    document.dispatchEvent(event);
  };

  return { showWarning, handleStayActive };
};

// The component that uses the hook with injection
export const InactivityTimer = inject(
  "store",
  "navixScribeV2Store"
)(
  observer(({ store, navixScribeV2Store }) => {
    const { showWarning, handleStayActive } = useInactivityTimerHook(
      store,
      navixScribeV2Store
    );

    return (
      <Modal open={showWarning} onClose={handleStayActive}>
        <div className="text-center w-96">
          <div className="mx-auto my-4">
            <h3 className="text-lg font-bold text-gray-800">
              Session Timeout Warning
            </h3>
            <p className="text-sm text-gray-500 mt-2">
              Your session will expire in 1 minute due to inactivity. Click
              "Stay Active" to continue your session.
            </p>
          </div>
          <div className="flex gap-4">
            <button
              type="button"
              className="w-full text-white bg-violet-600 hover:bg-violet-700 focus:ring-4 focus:outline-none focus:ring-violet-300 rounded-lg text-sm px-5 py-2.5 text-center"
              onClick={handleStayActive}
            >
              Stay Active
            </button>
          </div>
        </div>
      </Modal>
    );
  })
);
