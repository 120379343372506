import { inject, observer } from "mobx-react";
import {
  forwardRef,
  useEffect,
  useRef,
  useState,
  useMemo,
  useImperativeHandle,
} from "react";
import useWavesurfer from "../hooks/useWavesurfer";
import EntryAudio from "./EntryAudio";
import StreamingRecorder from "./StreamingRecorder";
import StreamingPlayer from "./StreamingPlayer";
import { formatTime } from "../utils/format-time";
// Icons for the recording button states
const micIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-6 h-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 18.75a6 6 0 006-6v-1.5m-6 7.5a6 6 0 01-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 01-3-3V4.5a3 3 0 116 0v8.25a3 3 0 01-3 3z"
    />
  </svg>
);

const stopIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-6 h-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M5.25 7.5A2.25 2.25 0 017.5 5.25h9a2.25 2.25 0 012.25 2.25v9a2.25 2.25 0 01-2.25 2.25h-9a2.25 2.25 0 01-2.25-2.25v-9z"
    />
  </svg>
);

const recordIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    className="w-6 h-6"
  >
    <path
      fillRule="evenodd"
      d="M4.5 5.653c0-1.426 1.529-2.33 2.779-1.643l11.54 6.348c1.295.712 1.295 2.573 0 3.285L7.28 19.991c-1.25.687-2.779-.217-2.779-1.643V5.653z"
      clipRule="evenodd"
    />
  </svg>
);

const clearIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-6 h-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6 18L18 6M6 6l12 12"
    />
  </svg>
);

const WaveSurferRecorder = inject(
  "store",
  "navixScribeV2Store"
)(
  observer(
    forwardRef(
      (
        {
          store,
          navixScribeV2Store,
          onAudioUrl,
          onBlob,
          // recording,
          onAudioChunk,
          onStartRecording,
          onStopRecording,
          uploadedFile,
          initialRecordingId,
          recordingStatus,
          ...props
        },
        ref
      ) => {
        const containerRef = useRef();
        const [recordingState, setRecordingState] = useState("default");
        const [audioUrl, setAudioUrl] = useState(null);
        const [elapsedTime, setElapsedTime] = useState(0);
        const timerRef = useRef(null);
        const [hasUploadedFile, setHasUploadedFile] = useState(false);

        // Add timer effect
        useEffect(() => {
          if (recordingState === "recording") {
            // Start from current elapsed time (useful for resume)
            const startTime = Date.now() - elapsedTime * 1000;

            timerRef.current = setInterval(() => {
              const elapsed = Math.floor((Date.now() - startTime) / 1000);
              setElapsedTime(elapsed);
            }, 1000);
          } else {
            // Clear timer when not recording
            if (timerRef.current) {
              clearInterval(timerRef.current);
            }
          }

          return () => {
            if (timerRef.current) {
              clearInterval(timerRef.current);
            }
          };
        }, [recordingState]);

        // Memoize wavesurfer options
        const wavesurferOptions = useMemo(
          () => ({
            backend: "MediaElement",
            waveColor: "violet",
            progressColor: "purple",
            cursorWidth: 1,
            normalize: true,
            minimap: true,
          }),
          []
        ); // Empty dependency array since these options don't change

        const wavesurfer = useWavesurfer(containerRef, wavesurferOptions);

        const { startRecording, stopRecording, isRecording } =
          StreamingRecorder({
            onChunk: (chunk, isFinal, metadata) => {
              onAudioChunk?.(chunk, isFinal, metadata);
            },
            onRecordingComplete: (finalBlob, metadata) => {
              navixScribeV2Store.stopRecording();
              // const url = URL.createObjectURL(finalBlob);
              // console.log("Recording complete:", url);
              setRecordingState("default");
            },
          });

        const onRecordClick = async () => {
          if (recordingState === "default") {
            try {
              await onStartRecording?.();
              setRecordingState("recording");
              setElapsedTime(0); // Reset timer for new recordings
              navixScribeV2Store.startRecording();
            } catch (error) {
              console.error("Failed to start recording:", error);
            }
          } else if (recordingState === "recording") {
            try {
              await onStopRecording?.();
              setRecordingState("default");
              navixScribeV2Store.stopRecording();
              navixScribeV2Store.setAudioDuration(elapsedTime);
            } catch (error) {
              console.error("Failed to stop recording:", error);
            }
          }
        };

        const handleClearUpload = () => {
          setAudioUrl(null);
          setHasUploadedFile(false);
          setRecordingState("default");
          if (uploadedFile) {
            URL.revokeObjectURL(URL.createObjectURL(uploadedFile));
          }
          // Call the onClear prop to notify parent component
          props.onClear?.();
        };

        useEffect(() => {
          if (uploadedFile) {
            const url = URL.createObjectURL(uploadedFile);
            setAudioUrl(url);
            setRecordingState("hasRecording");
            setHasUploadedFile(true);
            onAudioUrl?.(url);
            onBlob?.(uploadedFile);
          }
        }, [uploadedFile]);

        const handleResume = async (recordingId) => {
          try {
            await startRecording(recordingId);
            setRecordingState("recording");
            // Set elapsed time to the existing duration
            const existingDuration = navixScribeV2Store.recordingDuration || 0;
            setElapsedTime(existingDuration);
            navixScribeV2Store.setRecordingStatus("recording");
          } catch (error) {
            console.error("Failed to resume recording:", error);
          }
        };

        const handleReset = () => {
          navixScribeV2Store.resetRecording();
        };

        // Listen for stateReset event
        useEffect(() => {
          const handleStateReset = () => {
            // reset internal component state
            setRecordingState("default");
            setAudioUrl(null);
            setElapsedTime(0);
            setHasUploadedFile(false);

            if (timerRef.current) {
              clearInterval(timerRef.current);
              timerRef.current = null;
            }

            // revoke any blob URLs to prevent memory leaks
            if (audioUrl) {
              URL.revokeObjectURL(audioUrl);
            }
          };

          navixScribeV2Store.on("stateReset", handleStateReset);

          return () => {
            navixScribeV2Store.off("stateReset", handleStateReset);
          };
        }, [navixScribeV2Store, audioUrl]);

        // Expose recording methods through ref
        useImperativeHandle(ref, () => ({
          async startRecording() {
            const recordingId = await startRecording();
            return recordingId;
          },
          stopRecording() {
            stopRecording();
          },
        }));

        return (
          <div
            className="rounded-2xl shadow-xl bg-white flex flex-col items-center justify-center p-4 md:p-6"
            style={{
              width: "100%",
              maxWidth: "700px",
              height: "auto",
              minHeight: "200px",
            }}
          >
            {recordingState === "recording" && (
              <div className={`items-center gap-4 w-full flex flex-col`}>
                <div className="text-xl font-semibold text-violet-600 mb-4">
                  {formatTime(elapsedTime)}
                </div>
                <button
                  className={`flex justify-center items-center w-12 h-12 rounded-full bg-violet-600 text-white`}
                  onClick={onRecordClick}
                >
                  {stopIcon}
                </button>
              </div>
            )}

            {recordingState === "hasRecording" && audioUrl && (
              <div className="w-full flex flex-col items-center gap-4">
                <div className="w-full relative">
                  <EntryAudio
                    store={store}
                    audioUrl={audioUrl}
                    getAudioDuration={() => {}}
                    audioFile={null}
                  />
                </div>
                {hasUploadedFile && (
                  <button
                    onClick={handleClearUpload}
                    className="px-4 py-2 bg-red-50 text-red-600 rounded-lg hover:bg-red-100 transition-colors flex items-center gap-2 w-full md:w-auto justify-center"
                  >
                    {clearIcon}
                    <span>Clear Upload</span>
                  </button>
                )}
              </div>
            )}

            {navixScribeV2Store.recordingStatus === "completed" && (
              <StreamingPlayer
                recordingId={navixScribeV2Store.currentRecordingId}
                onReset={handleReset}
              />
            )}

            {!initialRecordingId && recordingState === "default" && (
              <div className={`flex justify-center items-center w-full`}>
                <button
                  className={`flex justify-center items-center px-4 py-3 rounded-lg bg-white text-gray-700 hover:bg-gray-50 transition-colors w-full md:w-auto`}
                  onClick={onRecordClick}
                >
                  <>
                    {micIcon}
                    <span className="ml-2">Record Encounter</span>
                  </>
                </button>
              </div>
            )}

            {initialRecordingId && recordingState !== "recording" && (
              <button
                onClick={() => handleResume(initialRecordingId)}
                className="px-4 py-2 bg-violet-600 text-white rounded-lg mb-8 w-full md:w-auto"
              >
                Resume Recording
              </button>
            )}
          </div>
        );
      }
    )
  )
);

export default WaveSurferRecorder;
