import React, { useState } from "react";
import { observer } from "mobx-react";
import { vobStore } from "../VOBStore";
import { LiveVOB as LiveVOBModel } from "../models/vob.types";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import OutlineButton from "./OutlineButton";
import clsx from "clsx";

const CollapsibleSection = ({ title, children, defaultOpen = true }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  return (
    <div className="mb-4">
      <button
        className="w-full flex justify-between items-center bg-gray-100 p-3 rounded-t-lg hover:bg-gray-200 transition-colors"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h3 className="text-sm font-medium text-gray-900">{title}</h3>
        {isOpen ? (
          <ChevronUpIcon className="h-5 w-5 text-gray-500" />
        ) : (
          <ChevronDownIcon className="h-5 w-5 text-gray-500" />
        )}
      </button>
      {isOpen && (
        <div className="border border-t-0 border-gray-200 rounded-b-lg p-4 bg-white">
          {children}
        </div>
      )}
    </div>
  );
};

const LiveVOB = observer(() => {
  const [formData, setFormData] = useState(new LiveVOBModel());

  const handleInputChange = (e, section = null) => {
    const { name, value, type, checked } = e.target;

    if (section) {
      setFormData((prev) => ({
        ...prev,
        [section]: {
          ...prev[section],
          [name]: type === "checkbox" ? checked : value,
        },
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  };

  const handleSave = () => {
    console.log("Saving LiveVOB data:", formData);
    // TODO: Implement actual save functionality
  };

  return (
    <div className="p-6">
      <div className="max-w-7xl mx-auto">
        <div className="space-y-4">
          {/* Header Section */}
          <div className="grid grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Assigned To*
              </label>
              <select
                name="assignedTo"
                value={formData.assignedTo}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              >
                <option value="">Select Assignee</option>
                {/* Add assignee options */}
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Location*
              </label>
              <select
                name="location"
                value={formData.location}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              >
                <option value="">Select Location</option>
                {/* Add location options */}
              </select>
            </div>
          </div>

          {/* Client Info Section */}
          <CollapsibleSection title="Client Info">
            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  First Name*
                </label>
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Last Name*
                </label>
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Date of Birth
                </label>
                <input
                  type="date"
                  name="dateOfBirth"
                  value={formData.dateOfBirth}
                  onChange={handleInputChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Gender
                </label>
                <input
                  type="text"
                  name="gender"
                  value={formData.gender}
                  onChange={handleInputChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Subscriber Name*
                </label>
                <input
                  type="text"
                  name="subscriberName"
                  value={formData.subscriberName}
                  onChange={handleInputChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Subscriber Date of Birth
                </label>
                <input
                  type="date"
                  name="subscriberDateOfBirth"
                  value={formData.subscriberDateOfBirth}
                  onChange={handleInputChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Relationship to Client
                </label>
                <input
                  type="text"
                  name="relationshipToClient"
                  value={formData.relationshipToClient}
                  onChange={handleInputChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Representative Name
                </label>
                <input
                  type="text"
                  name="representativeName"
                  value={formData.representativeName}
                  onChange={handleInputChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
              <div className="col-span-2">
                <label className="block text-sm font-medium text-gray-700">
                  Employer Name*
                </label>
                <input
                  type="text"
                  name="employerName"
                  value={formData.employerName}
                  onChange={handleInputChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
              <div className="col-span-2">
                <label className="block text-sm font-medium text-gray-700">
                  Policy Type
                </label>
                <div className="mt-2 space-x-4">
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      name="policyType"
                      value="employer"
                      checked={formData.policyType === "employer"}
                      onChange={handleInputChange}
                      className="form-radio h-4 w-4 text-indigo-600 border-gray-300"
                    />
                    <span className="ml-2">Employer</span>
                  </label>
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      name="policyType"
                      value="individual"
                      checked={formData.policyType === "individual"}
                      onChange={handleInputChange}
                      className="form-radio h-4 w-4 text-indigo-600 border-gray-300"
                    />
                    <span className="ml-2">Individual</span>
                  </label>
                </div>
              </div>
            </div>
          </CollapsibleSection>

          {/* Contact/Policy Info Section */}
          <CollapsibleSection title="Contact/Policy Information">
            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Address on Policy
                </label>
                <input
                  type="text"
                  name="addressOnPolicy"
                  value={formData.addressOnPolicy}
                  onChange={handleInputChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Time/Date of VOB
                </label>
                <input
                  type="datetime-local"
                  name="timeOfVOB"
                  value={formData.timeOfVOB}
                  onChange={handleInputChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Call Reference No.
                </label>
                <input
                  type="text"
                  name="callReferenceNo"
                  value={formData.callReferenceNo}
                  onChange={handleInputChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Pre-cert Phone No.
                </label>
                <input
                  type="tel"
                  name="preCertPhoneNo"
                  value={formData.preCertPhoneNo}
                  onChange={handleInputChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
              {/* Add remaining contact/policy fields */}
            </div>
          </CollapsibleSection>

          {/* Coverage Details Section */}
          <CollapsibleSection title="Coverage Details">
            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Effective Date
                </label>
                <input
                  type="date"
                  name="effectiveDate"
                  value={formData.effectiveDate}
                  onChange={handleInputChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Plan Funding
                </label>
                <div className="mt-2 space-x-4">
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      name="planFunding"
                      value="self"
                      checked={formData.planFunding === "self"}
                      onChange={handleInputChange}
                      className="form-radio h-4 w-4 text-indigo-600 border-gray-300"
                    />
                    <span className="ml-2">Self Funded</span>
                  </label>
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      name="planFunding"
                      value="full"
                      checked={formData.planFunding === "full"}
                      onChange={handleInputChange}
                      className="form-radio h-4 w-4 text-indigo-600 border-gray-300"
                    />
                    <span className="ml-2">Fully Funded</span>
                  </label>
                </div>
              </div>
              <div className="col-span-2">
                <label className="block text-sm font-medium text-gray-700">
                  Active Coverage
                </label>
                <div className="mt-2 space-x-4">
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      name="activeCoverage"
                      checked={formData.activeCoverage}
                      onChange={(e) =>
                        handleInputChange({
                          target: {
                            name: "activeCoverage",
                            value: true,
                          },
                        })
                      }
                      className="form-radio h-4 w-4 text-indigo-600 border-gray-300"
                    />
                    <span className="ml-2">Active</span>
                  </label>
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      name="activeCoverage"
                      checked={!formData.activeCoverage}
                      onChange={(e) =>
                        handleInputChange({
                          target: {
                            name: "activeCoverage",
                            value: false,
                          },
                        })
                      }
                      className="form-radio h-4 w-4 text-indigo-600 border-gray-300"
                    />
                    <span className="ml-2">Inactive</span>
                  </label>
                </div>
              </div>
            </div>
          </CollapsibleSection>

          {/* Reimbursement Details Section */}
          <CollapsibleSection title="Reimbursement Details">
            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    name="aobAccepted"
                    checked={formData.aobAccepted}
                    onChange={handleInputChange}
                    className="form-checkbox h-4 w-4 text-indigo-600 border-gray-300 rounded"
                  />
                  <span className="ml-2">AOB Accepted</span>
                </label>
              </div>
              <div>
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    name="oonAllowedAmount"
                    checked={formData.oonAllowedAmount}
                    onChange={handleInputChange}
                    className="form-checkbox h-4 w-4 text-indigo-600 border-gray-300 rounded"
                  />
                  <span className="ml-2">OON Allowed Amount</span>
                </label>
              </div>
              <div>
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    name="medicareRates"
                    checked={formData.medicareRates}
                    onChange={handleInputChange}
                    className="form-checkbox h-4 w-4 text-indigo-600 border-gray-300 rounded"
                  />
                  <span className="ml-2">Medicare Rates</span>
                </label>
              </div>
              <div>
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    name="usualAndCustomary"
                    checked={formData.usualAndCustomary}
                    onChange={handleInputChange}
                    className="form-checkbox h-4 w-4 text-indigo-600 border-gray-300 rounded"
                  />
                  <span className="ml-2">Usual and Customary</span>
                </label>
              </div>
              <div>
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    name="priceByLocalPlan"
                    checked={formData.priceByLocalPlan}
                    onChange={handleInputChange}
                    className="form-checkbox h-4 w-4 text-indigo-600 border-gray-300 rounded"
                  />
                  <span className="ml-2">Price by Local Plan</span>
                </label>
              </div>
            </div>
          </CollapsibleSection>

          {/* Level of Care Section */}
          <CollapsibleSection title="Level of Care Covered">
            <div className="grid grid-cols-3 gap-6">
              {Object.entries(formData.levelOfCare).map(([key, value]) => (
                <div key={key}>
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      name={key}
                      checked={value}
                      onChange={(e) => handleInputChange(e, "levelOfCare")}
                      className="form-checkbox h-4 w-4 text-indigo-600 border-gray-300 rounded"
                    />
                    <span className="ml-2">
                      {key
                        .split(/(?=[A-Z])/)
                        .map(
                          (word) => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(" ")}
                    </span>
                  </label>
                </div>
              ))}
            </div>
          </CollapsibleSection>

          {/* Pre-Certification Section */}
          <CollapsibleSection title="Pre-Certification/Authorization Required For">
            <div className="grid grid-cols-3 gap-6">
              {Object.entries(formData.preCertification).map(([key, value]) => (
                <div key={key}>
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      name={key}
                      checked={value}
                      onChange={(e) => handleInputChange(e, "preCertification")}
                      className="form-checkbox h-4 w-4 text-indigo-600 border-gray-300 rounded"
                    />
                    <span className="ml-2">{key.toUpperCase()}</span>
                  </label>
                </div>
              ))}
            </div>
            <div className="mt-4">
              <label className="block text-sm font-medium text-gray-700">
                COB on File (Last Updated)
              </label>
              <input
                type="date"
                name="lastUpdated"
                value={formData.cobOnFile.lastUpdated}
                onChange={(e) => handleInputChange(e, "cobOnFile")}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
          </CollapsibleSection>

          {/* Insurance Specific Sections */}
          <CollapsibleSection title="Insurance Specific Details">
            <div className="space-y-6">
              {/* AETNA */}
              <div>
                <h4 className="text-sm font-medium text-gray-900 mb-4">
                  AETNA Reimbursements
                </h4>
                <div className="grid grid-cols-2 gap-4">
                  {Object.entries(formData.aetna).map(([key, value]) => (
                    <div key={key}>
                      <label className="inline-flex items-center">
                        <input
                          type="checkbox"
                          name={key}
                          checked={value}
                          onChange={(e) => handleInputChange(e, "aetna")}
                          className="form-checkbox h-4 w-4 text-indigo-600 border-gray-300 rounded"
                        />
                        <span className="ml-2">
                          {key
                            .split(/(?=[A-Z])/)
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() + word.slice(1)
                            )
                            .join(" ")}
                        </span>
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              {/* CIGNA */}
              <div>
                <h4 className="text-sm font-medium text-gray-900 mb-4">
                  CIGNA Reimbursements
                </h4>
                <div className="grid grid-cols-2 gap-4">
                  {Object.entries(formData.cigna).map(([key, value]) => (
                    <div key={key}>
                      <label className="inline-flex items-center">
                        <input
                          type="checkbox"
                          name={key}
                          checked={value}
                          onChange={(e) => handleInputChange(e, "cigna")}
                          className="form-checkbox h-4 w-4 text-indigo-600 border-gray-300 rounded"
                        />
                        <span className="ml-2">{key.toUpperCase()}</span>
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              {/* Blue */}
              <div>
                <h4 className="text-sm font-medium text-gray-900 mb-4">
                  Blue (send to your local)
                </h4>
                <div className="grid grid-cols-2 gap-4">
                  {Object.entries(formData.blue).map(([key, value]) => (
                    <div key={key}>
                      <label className="inline-flex items-center">
                        <input
                          type="checkbox"
                          name={key}
                          checked={value}
                          onChange={(e) => handleInputChange(e, "blue")}
                          className="form-checkbox h-4 w-4 text-indigo-600 border-gray-300 rounded"
                        />
                        <span className="ml-2">
                          {key
                            .split(/(?=[A-Z])/)
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() + word.slice(1)
                            )
                            .join(" ")}
                        </span>
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </CollapsibleSection>

          {/* Plan Level Details Section */}
          <CollapsibleSection title="Plan Level Coinsurance, Deductible, and Maximums">
            <div className="grid grid-cols-2 gap-6">
              {Object.entries(formData.planLevel).map(([key, value]) => {
                if (key === "isDeductibleIncludedInOOPM") {
                  return (
                    <div key={key} className="col-span-2">
                      <label className="inline-flex items-center">
                        <input
                          type="checkbox"
                          name={key}
                          checked={value}
                          onChange={(e) => handleInputChange(e, "planLevel")}
                          className="form-checkbox h-4 w-4 text-indigo-600 border-gray-300 rounded"
                        />
                        <span className="ml-2">
                          Is the deductible included in the OOPM?
                        </span>
                      </label>
                    </div>
                  );
                }
                return (
                  <div key={key}>
                    <label className="block text-sm font-medium text-gray-700">
                      {key
                        .split(/(?=[A-Z])/)
                        .map(
                          (word) => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(" ")}
                    </label>
                    <input
                      type="text"
                      name={key}
                      value={value}
                      onChange={(e) => handleInputChange(e, "planLevel")}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                );
              })}
            </div>
          </CollapsibleSection>

          {/* Pharmacy Details Section */}
          <CollapsibleSection title="Pharmacy Details">
            <div className="grid grid-cols-2 gap-6">
              {Object.entries(formData.pharmacy).map(([key, value]) => (
                <div key={key}>
                  <label className="block text-sm font-medium text-gray-700">
                    {key
                      .split(/(?=[A-Z])/)
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(" ")}
                  </label>
                  <input
                    type="text"
                    name={key}
                    value={value}
                    onChange={(e) => handleInputChange(e, "pharmacy")}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              ))}
            </div>
          </CollapsibleSection>

          {/* Additional Notes Section */}
          <CollapsibleSection title="Additional Notes">
            <div>
              <textarea
                name="additionalNotes"
                value={formData.additionalNotes}
                onChange={handleInputChange}
                rows={4}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
          </CollapsibleSection>

          {/* Save Button */}
          <div className="flex justify-end">
            <OutlineButton onClick={handleSave}>Save</OutlineButton>
          </div>
        </div>
      </div>
    </div>
  );
});

export default LiveVOB;
