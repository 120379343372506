import { ViewListIcon } from "@heroicons/react/solid";

const obj = {
  title: "Queues",
  desc: "Access and manage queues.",
  Icon: ViewListIcon,
  fromColor: "gray-500",
  to: "/queues",
  api: "/queues",
  permission: "admin",
};

export default obj;
