import React, { useEffect, useState, useRef, useMemo } from "react";
import { inject, observer } from "mobx-react";
import Loader from "../../../components/Loader";
import styled from "styled-components";
import moment from "moment";
import { fetchThreadHistories } from "../api.service.v2";
import {
  ChevronRightIcon,
  ChevronLeftIcon,
  PlusIcon,
  ChatIcon,
  DotsVerticalIcon,
  TrashIcon,
  ChevronDownIcon,
  CheckIcon,
  UserIcon,
  DocumentTextIcon,
  UserRemoveIcon,
  PencilIcon,
  AdjustmentsIcon,
  XCircleIcon,
  RefreshIcon,
} from "@heroicons/react/outline";
import { runInAction } from "mobx";
import {
  createClient,
  updateClientStatus,
  deleteClient,
  getClients,
  assignThreadToClient,
  updateClientName,
} from "../client.service";
import { Dialog } from "@headlessui/react";
import ClientModal from "./ClientModal";
import {
  fetchProcessingHistories,
  deleteProcessingHistory,
} from "../processing-histories.service";
import ProcessingHistoryLoader from "./ProcessingHistoryLoader";
import { retranscribeDraft } from "../api.service.v2";

const HistoryPanel = inject(
  "store",
  "navixScribeV2Store"
)(
  observer(
    ({
      store,
      navixScribeV2Store,
      isCollapsed,
      onToggleCollapse,
      onOpenClientModal,
      isClientModalOpen,
      onCloseClientModal,
    }) => {
      const [histories, setHistories] = useState([]);
      const [loading, setLoading] = useState(true);
      const [backgroundLoading, setBackgroundLoading] = useState(false);
      const [activeTab, setActiveTab] = useState("active");
      const [isClientMenuOpen, setIsClientMenuOpen] = useState(null);
      const [clients, setClients] = useState([]);
      const [editingClientId, setEditingClientId] = useState(null);
      const [editingClientName, setEditingClientName] = useState("");
      const [expandedSections, setExpandedSections] = useState({});
      const [searchQuery, setSearchQuery] = useState("");
      const [processingHistories, setProcessingHistories] = useState([]);
      const [sortBy, setSortBy] = useState("created");
      const [sortOrder, setSortOrder] = useState("desc");
      const [showSortMenu, setShowSortMenu] = useState(false);
      const sortMenuRef = useRef(null);

      const groupedHistories = useMemo(() => {
        const grouped = {
          active: {},
          discharged: {},
          processingHistories: [],
          unassigned: [],
        };

        if (Array.isArray(clients)) {
          clients.forEach((client) => {
            const status = client.status || "active";
            if (!grouped[status][client._id]) {
              grouped[status][client._id] = {
                histories: [],
                clientId: client._id,
                status: client.status,
                name: client.name,
              };
            }
          });
        }

        if (Array.isArray(processingHistories)) {
          processingHistories.forEach((processingHistory) => {
            if (processingHistory?.isVisible) {
              grouped.processingHistories.push(processingHistory);
            }
          });
        }

        if (Array.isArray(histories)) {
          histories.forEach((history) => {
            if (
              !history?.assignedToClient ||
              history.assignedToClient === "unassigned"
            ) {
              grouped.unassigned.push(history);
            } else {
              const client = clients.find(
                (c) => c._id === history.assignedToClient._id
              );
              if (client) {
                const status = client.status || "active";
                grouped[status][client._id].histories.push(history);
              } else {
                grouped.unassigned.push(history);
              }
            }
          });
        }

        return grouped;
      }, [histories, clients, processingHistories]);

      const filteredGroupedHistories = useMemo(() => {
        const filtered = {
          active: {},
          discharged: {},
          processingHistories: groupedHistories.processingHistories || [],
          unassigned: groupedHistories.unassigned || [],
        };

        Object.entries(groupedHistories).forEach(([status, data]) => {
          if (status !== "unassigned" && status !== "processingHistories") {
            Object.entries(data).forEach(([clientId, clientData]) => {
              const matchesSearch =
                clientData.name
                  ?.toLowerCase()
                  .includes(searchQuery.toLowerCase()) ||
                clientData.histories.some((history) =>
                  history.title
                    ?.toLowerCase()
                    .includes(searchQuery.toLowerCase())
                );

              if (matchesSearch) {
                if (!filtered[status]) {
                  filtered[status] = {};
                }
                filtered[status][clientId] = clientData;
              }
            });
          }
        });

        return filtered;
      }, [groupedHistories, searchQuery]);

      const [selectedThreadId, setSelectedThreadId] = useState(null);
      const [showDelete, setShowDelete] = useState(null);
      const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
      const [deleteThreadId, setDeleteThreadId] = useState(null);
      const deleteDropdownRef = useRef(null);

      const titleRef = useRef(null);
      const clientMenuRef = useRef(null);
      const handleDelete = async () => {
        if (deleteThreadId) {
          try {
            console.log(`Confirming delete for threadId: ${deleteThreadId}`);
            const history = histories.find((h) => h._id === deleteThreadId);
            if (history) {
              console.log(`Found history: ${JSON.stringify(history)}`);
              const response = await store.api.delete(
                `/histories/${history._id}`
              );
              console.log(`API response: ${response.status}`);
              if (response.status === 200) {
                setHistories((prevHistories) =>
                  prevHistories.filter((h) => h._id !== history._id)
                );
                console.log(`History deleted: ${history._id}`);
              } else {
                console.error("Failed to delete history");
              }
            } else {
              console.error("History not found for threadId:", deleteThreadId);
              console.log("Available histories:", histories);
            }
          } catch (error) {
            console.error("Error deleting history:", error);
          } finally {
            setIsDeleteModalOpen(false);
            setDeleteThreadId(null);
          }
        } else {
          console.error("No deleteThreadId set");
        }
      };

      const fetchAllData = async (shouldShowLoading = true) => {
        try {
          if (shouldShowLoading) {
            setLoading(true);
          } else {
            setBackgroundLoading(true);
          }

          const [
            historiesResponse,
            clientsResponse,
            processingHistoriesResponse,
          ] = await Promise.all([
            fetchThreadHistories(),
            getClients({
              search: searchQuery,
              sortBy,
              sortOrder,
              status: activeTab,
            }),
            fetchProcessingHistories(),
          ]);

          const historiesData = historiesResponse.result.map((history) => ({
            ...history,
            isThreadHistory: true, // Mark as thread history
          }));

          const processingHistoriesData = (
            processingHistoriesResponse?.processingHistories || []
          )
            .filter((processingHistory) => processingHistory?.isVisible)
            .map((processingHistory) => ({
              ...processingHistory,
              isProcessingHistory: true, // Mark as processing history
            }));

          setHistories(historiesData);
          setClients(clientsResponse.result || []);
          setProcessingHistories(processingHistoriesData);
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          if (shouldShowLoading) {
            setLoading(false);
          } else {
            setBackgroundLoading(false);
          }
        }
      };

      useEffect(() => {
        fetchAllData(true); // Initial load with loading indicator
      }, []);

      useEffect(() => {
        const refreshHistories = () => {
          fetchAllData(false); // Refresh without loading indicator
        };

        // Listen for store events
        navixScribeV2Store.on("processingHistoryCreated", (id) => {
          refreshHistories();
        });
        navixScribeV2Store.on("transcriptionCompleted", () => {
          refreshHistories();
        });

        // Listen for socket events
        const socket = store.socket;
        if (socket) {
          socket.on("processing-history-created", (data) => {
            refreshHistories();
          });
          socket.on("transcription-completed", (data) => {
            refreshHistories();
          });
        } else {
          console.warn("[Socket Debug] No socket connection available");
        }

        return () => {
          // Clean up store event listeners
          navixScribeV2Store.off("processingHistoryCreated", refreshHistories);
          navixScribeV2Store.off("transcriptionCompleted", refreshHistories);

          // Clean up socket event listeners
          if (socket) {
            socket.off("processing-history-created", refreshHistories);
            socket.off("transcription-completed", refreshHistories);
          }
        };
      }, [navixScribeV2Store, store.socket]);

      useEffect(() => {
        const handleClickOutside = (event) => {
          if (
            deleteDropdownRef.current &&
            !deleteDropdownRef.current.contains(event.target) &&
            !event.target.closest("[data-ignore-outside-click]")
          ) {
            setShowDelete(null);
          }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [deleteDropdownRef]);

      useEffect(() => {
        setSelectedThreadId(navixScribeV2Store.selectedThread);
      }, [navixScribeV2Store.selectedThread]);

      const handleHistoryClick = async (threadId) => {
        try {
          setSelectedThreadId(threadId);
          await navixScribeV2Store.selectThread(threadId);
        } catch (error) {
          console.error("Error selecting thread:", error);
        }
      };

      const toggleCollapse = () => {
        const newCollapsedState = !isCollapsed;
        onToggleCollapse(newCollapsedState);
      };

      useEffect(() => {
        const header = document.querySelector(
          ".border-b.border-gray-300.bg-gray-50"
        );
        const spacer = header?.nextElementSibling;
        if (header && spacer) {
          const leftOffset = isCollapsed ? "90px" : "300px";
          header.style.left = leftOffset;
          spacer.style.marginLeft = leftOffset;
        }
      }, [isCollapsed]);

      const handleClientStatusChange = async (clientId, newStatus) => {
        try {
          await updateClientStatus(clientId, newStatus);
          fetchAllData();
          setIsClientMenuOpen(null);
        } catch (error) {
          console.error("Error updating client status:", error);
        }
      };

      const handleClientDelete = async (clientId) => {
        try {
          await store.api.put(`/histories/unassign-client/${clientId}`);

          await deleteClient(clientId);

          await fetchAllData();
          setIsClientMenuOpen(null);
        } catch (error) {
          console.error("Error deleting client:", error);
        }
      };

      const handleAssignClient = async (historyId, clientId) => {
        try {
          await assignThreadToClient(historyId, clientId);
          await fetchAllData();
        } catch (error) {
          console.error("Error assigning client:", error);
        }
      };

      const handleClientModalClose = () => {
        onCloseClientModal();
      };

      useEffect(() => {
        const handleClickOutside = (event) => {
          if (
            clientMenuRef.current &&
            !clientMenuRef.current.contains(event.target)
          ) {
            setIsClientMenuOpen(null);
          }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, []);

      const handleClientRename = async (clientId, newName) => {
        try {
          await updateClientName(clientId, newName);
          const clientsResponse = await getClients();
          setClients(clientsResponse.result || []);

          setHistories((prevHistories) =>
            prevHistories.map((history) => {
              if (history.assignedToClient?._id === clientId) {
                return {
                  ...history,
                  assignedToClient: {
                    ...history.assignedToClient,
                    name: newName,
                  },
                };
              }
              return history;
            })
          );

          setEditingClientId(null);
          setEditingClientName("");
        } catch (error) {
          console.error("Error renaming client:", error);
        }
      };

      const toggleSection = (clientId) => {
        setExpandedSections((prev) => ({
          ...prev,
          [clientId]: !prev[clientId],
        }));
      };

      useEffect(() => {
        const timer = setTimeout(() => {
          fetchAllData(false); // use false to avoid loading state during filtering
        }, 300);

        return () => clearTimeout(timer);
      }, [searchQuery, sortBy, sortOrder, activeTab]);

      useEffect(() => {
        const handleClickOutside = (event) => {
          if (
            sortMenuRef.current &&
            !sortMenuRef.current.contains(event.target)
          ) {
            setShowSortMenu(false);
          }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, []);

      const handleSort = (field) => {
        if (sortBy === field) {
          setSortOrder(sortOrder === "asc" ? "desc" : "asc");
        } else {
          setSortBy(field);
          setSortOrder("asc");
        }
        setShowSortMenu(false);
      };

      if (loading) {
        return (
          <Wrapper isCollapsed={isCollapsed}>
            {!isCollapsed && (
              <Container isCollapsed={isCollapsed}>
                <div className="flex justify-center items-center w-full h-full">
                  <div className="flex flex-col items-center">
                    <Loader active={true} className="w-16 h-16" />
                    <p className="text-xs text-gray-500 mt-2">
                      Loading your encounters...
                    </p>
                  </div>
                </div>
              </Container>
            )}
            {isCollapsed && (
              <div className="flex justify-center items-center w-full h-full">
                <Loader active={true} className="w-8 h-8" />
              </div>
            )}
          </Wrapper>
        );
      }

      return (
        <Wrapper isCollapsed={isCollapsed}>
          {/* <CollapseButton onClick={toggleCollapse}>
          {isCollapsed ? (
            <ChevronRightIcon className="w-6 h-6 text-white" />
          ) : (
            <ChevronLeftIcon className="w-6 h-6 text-white" />
          )}
        </CollapseButton> */}

          {!isCollapsed && (
            <Container isCollapsed={isCollapsed}>
              {backgroundLoading && <BackgroundLoadingIndicator />}
              <NewEncounterButton
                isCollapsed={isCollapsed}
                onClick={() => {
                  navixScribeV2Store.createNewThread();
                  navixScribeV2Store.resetThreadState();
                  navixScribeV2Store.resetRecording();
                  navixScribeV2Store.setRecordingStatus("idle");
                  navixScribeV2Store.setCurrentRecordingId(null);
                  navixScribeV2Store.setAudioDuration(0);
                  navixScribeV2Store.emit("stateReset");
                }}
              >
                <PlusIcon className="w-5 h-5 text-violet-900" />
                {!isCollapsed && <span>New Encounter</span>}
              </NewEncounterButton>

              <TabContainer>
                <TabButton
                  active={activeTab === "active"}
                  onClick={() => setActiveTab("active")}
                >
                  Active Clients
                </TabButton>
                <TabButton
                  active={activeTab === "discharged"}
                  onClick={() => setActiveTab("discharged")}
                >
                  Discharged Clients
                </TabButton>
                <AddClientButton onClick={onOpenClientModal}>
                  <PlusIcon className="w-4 h-4" />
                </AddClientButton>
              </TabContainer>

              <SearchContainer>
                <div className="relative flex items-center gap-2">
                  <input
                    type="text"
                    placeholder="Search clients..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="w-full px-3 py-2 rounded-lg border border-gray-200 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                  />
                  <div className="relative">
                    <button
                      onClick={() => setShowSortMenu(!showSortMenu)}
                      className="p-2 hover:bg-gray-100 rounded-lg"
                    >
                      <AdjustmentsIcon className="w-5 h-5 text-gray-600" />
                    </button>
                    {showSortMenu && (
                      <ActionMenu ref={sortMenuRef} className="right-0 mt-2">
                        <div className="px-3 py-1 text-xs text-gray-500">
                          Sort by:
                        </div>
                        <MenuButton
                          onClick={() => handleSort("name")}
                          className={sortBy === "name" ? "bg-purple-50" : ""}
                        >
                          Name{" "}
                          {sortBy === "name" &&
                            (sortOrder === "asc" ? "↑" : "↓")}
                        </MenuButton>
                        <MenuButton
                          onClick={() => handleSort("created")}
                          className={sortBy === "created" ? "bg-purple-50" : ""}
                        >
                          Date{" "}
                          {sortBy === "created" &&
                            (sortOrder === "asc" ? "↑" : "↓")}
                        </MenuButton>
                        <MenuButton
                          onClick={() => handleSort("status")}
                          className={sortBy === "status" ? "bg-purple-50" : ""}
                        >
                          Status{" "}
                          {sortBy === "status" &&
                            (sortOrder === "asc" ? "↑" : "↓")}
                        </MenuButton>
                      </ActionMenu>
                    )}
                  </div>
                </div>
              </SearchContainer>

              <HistoryList>
                {Object.entries(filteredGroupedHistories[activeTab]).map(
                  ([clientId, clientData]) => (
                    <Section key={clientId}>
                      <ClientContainer>
                        <ClientNameContainer
                          isMenuOpen={isClientMenuOpen === clientData.clientId}
                          onClick={() => toggleSection(clientData.clientId)}
                        >
                          <ClientNameSection>
                            <div className="flex items-center gap-2">
                              <UserIcon className="w-5 h-5 text-gray-600" />
                              {editingClientId === clientData.clientId ? (
                                <form
                                  onClick={(e) => e.stopPropagation()}
                                  onSubmit={(e) => {
                                    e.preventDefault();
                                    handleClientRename(
                                      clientData.clientId,
                                      editingClientName
                                    );
                                  }}
                                  className="flex items-center"
                                >
                                  <input
                                    type="text"
                                    value={editingClientName}
                                    onChange={(e) =>
                                      setEditingClientName(e.target.value)
                                    }
                                    className="border border-gray-300 rounded px-2 py-1 text-sm"
                                    autoFocus
                                    onBlur={() => {
                                      if (
                                        editingClientName !== clientData.name
                                      ) {
                                        handleClientRename(
                                          clientData.clientId,
                                          editingClientName
                                        );
                                      }
                                      setEditingClientId(null);
                                    }}
                                  />
                                </form>
                              ) : (
                                <SectionTitle className="font-medium">
                                  {clientData.name}{" "}
                                  <span className="text-gray-500">
                                    ({clientData.histories.length})
                                  </span>
                                </SectionTitle>
                              )}
                            </div>
                            <ChevronDownIcon
                              className={`w-5 h-5 text-gray-500 transition-transform duration-200 ${
                                expandedSections[clientData.clientId]
                                  ? "transform rotate-180"
                                  : ""
                              }`}
                            />
                          </ClientNameSection>
                          <IconWrapper onClick={(e) => e.stopPropagation()}>
                            <DotsVerticalIcon
                              className="w-5 h-5 text-gray-500 hover:text-gray-700 cursor-pointer"
                              onClick={(e) => {
                                e.stopPropagation();
                                setIsClientMenuOpen((prev) =>
                                  prev === clientData.clientId
                                    ? null
                                    : clientData.clientId
                                );
                              }}
                            />
                            {isClientMenuOpen === clientData.clientId && (
                              <ActionMenu ref={clientMenuRef}>
                                <MenuButton
                                  onClick={() => {
                                    setEditingClientId(clientData.clientId);
                                    setEditingClientName(clientData.name);
                                    setIsClientMenuOpen(null);
                                  }}
                                >
                                  <PencilIcon className="w-4 h-4" />
                                  Rename
                                </MenuButton>
                                <MenuButton
                                  onClick={() =>
                                    handleClientStatusChange(
                                      clientData.clientId,
                                      clientData.status === "active"
                                        ? "discharged"
                                        : "active"
                                    )
                                  }
                                >
                                  {clientData.status === "active"
                                    ? "Move to Discharged"
                                    : "Move to Active"}
                                </MenuButton>
                                <MenuDivider />
                                <MenuButton
                                  onClick={() =>
                                    handleClientDelete(clientData.clientId)
                                  }
                                  className="text-red-600 hover:bg-red-50"
                                >
                                  <TrashIcon className="w-4 h-4" />
                                  Delete Client
                                </MenuButton>
                              </ActionMenu>
                            )}
                          </IconWrapper>
                        </ClientNameContainer>

                        {expandedSections[clientData.clientId] && (
                          <HistoriesContainer>
                            {clientData.histories.map((history) => (
                              <HistoryItem
                                key={history._id}
                                history={history}
                                isActive={
                                  navixScribeV2Store.selectedThread ===
                                  history.threadId
                                }
                                isCollapsed={isCollapsed}
                                onHistoryClick={handleHistoryClick}
                                clients={clients}
                                onAssignClient={handleAssignClient}
                                onRefresh={fetchAllData}
                              />
                            ))}
                          </HistoriesContainer>
                        )}
                      </ClientContainer>
                    </Section>
                  )
                )}

                {activeTab === "active" &&
                  groupedHistories.processingHistories.length > 0 && (
                    <Section>
                      <SectionHeader>
                        <div className="flex items-center gap-2">
                          <span className="text-gray-400">Processing</span>
                          <span className="text-gray-400">
                            ({groupedHistories.processingHistories.length})
                          </span>
                        </div>
                      </SectionHeader>
                      {groupedHistories.processingHistories.map(
                        (processingHistory) => (
                          <HistoryItem
                            key={processingHistory._id}
                            history={processingHistory}
                            isActive={
                              selectedThreadId === processingHistory.threadId
                            }
                            isCollapsed={isCollapsed}
                            onHistoryClick={handleHistoryClick}
                            clients={clients}
                            onAssignClient={handleAssignClient}
                            onRefresh={fetchAllData}
                          />
                        )
                      )}
                    </Section>
                  )}

                {activeTab === "active" &&
                  groupedHistories.unassigned.length > 0 && (
                    <Section>
                      <SectionHeader>
                        <div className="flex items-center gap-2">
                          <span className="text-gray-400">Unassigned</span>
                          <span className="text-gray-400">
                            ({groupedHistories.unassigned.length})
                          </span>
                        </div>
                      </SectionHeader>
                      {groupedHistories.unassigned.map((history) => (
                        <HistoryItem
                          key={history._id}
                          history={history}
                          isActive={selectedThreadId === history.threadId}
                          isCollapsed={isCollapsed}
                          onHistoryClick={handleHistoryClick}
                          clients={clients}
                          onAssignClient={handleAssignClient}
                          onRefresh={fetchAllData}
                        />
                      ))}
                    </Section>
                  )}
              </HistoryList>
            </Container>
          )}

          <ClientModal
            isOpen={isClientModalOpen}
            onClose={handleClientModalClose}
            onClientCreated={fetchAllData}
          />
          <DeleteConfirmationModal
            isOpen={isDeleteModalOpen}
            onClose={() => setIsDeleteModalOpen(false)}
            onConfirm={handleDelete}
          />
        </Wrapper>
      );
    }
  )
);

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  width: ${(props) => (props.isCollapsed ? "90px" : "300px")};
  background-color: #f8f8f8;
  height: 100vh;
  z-index: 10;
  will-change: transform, width;
  transform: translateZ(0);

  @media (max-width: 768px) {
    top: 0px; /* Account for mobile navbar height */
    width: 340px;
    transform: ${(props) =>
      props.isCollapsed ? "translateX(-100%)" : "translateX(0)"};
    box-shadow: ${(props) =>
      props.isCollapsed ? "none" : "0 0 10px rgba(0, 0, 0, 0.2)"};
    z-index: 50;
    height: 100vh;
  }
`;

const CollapseButton = styled.div`
  position: absolute;
  top: 50%;
  right: -15px;
  transform: translateY(-50%);
  background-color: #5c4bdb;
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;
`;

const Container = styled.div`
  padding: ${(props) => (props.isCollapsed ? "0" : "20px 10px")};
  background-color: #f8f8f8;
  border-radius: 8px;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: ${(props) => (props.isCollapsed ? "center" : "flex-start")};
  will-change: padding, width;
  transition: all 0.3s ease;
  transform: translateZ(0);
`;

const NewEncounterButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  background-color: white;
  border-radius: 12px;
  margin-bottom: 20px;
  cursor: pointer;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  width: ${(props) => (props.isCollapsed ? "40px" : "160px")};

  &:hover {
    background-color: #f3f4f6;
  }

  span {
    margin-left: 8px;
    font-size: 14px;
    font-weight: 500;
    color: #333;
  }
`;

const HistoryList = styled.div`
  flex-grow: 0.1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: visible;
`;

const NoHistoriesMessage = styled.div`
  text-align: center;
  color: #666;
  font-size: 14px;
  line-height: 1.4;
  max-width: 200px;
  margin: 0 auto;
`;

const NoHistoriesIcon = styled.div`
  font-size: 48px;
  margin-bottom: 16px;
  color: #999;
`;

const IconWrapper = styled.div`
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  z-index: 1;

  &.active {
    z-index: 1002;
  }
`;

const TitleWrapper = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  max-width: 179px;

  h3 {
    font-size: 14px;
    font-weight: 600;
    color: #333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    position: relative;
  }

  h3.truncate {
    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 20px;
    }
  }

  p {
    font-size: 12px;
    color: #888;
    font-weight: 400;
    margin-top: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const HistoryItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  margin-bottom: 8px;
  background-color: ${(props) => (props.isActive ? "#5c4bdb" : "white")};
  border-radius: 8px;
  cursor: ${(props) => (props.isProcessingHistory ? "default" : "pointer")};
  transition: all 0.3s ease;
  position: relative;
  width: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  z-index: ${(props) => (props.showMenu ? 1001 : 1)};

  &:hover {
    background-color: ${(props) =>
      props.isProcessingHistory ? "transparent" : "#ede9fe"};
  }

  ${IconWrapper} {
    color: ${(props) => (props.isActive ? "white" : "#666")};
  }

  ${TitleWrapper} {
    h3 {
      color: ${(props) => (props.isActive ? "white" : "#333")};
    }
    p {
      color: ${(props) => (props.isActive ? "rgba(255,255,255,0.8)" : "#888")};
    }
  }

  &:hover ${IconWrapper}.dots {
    visibility: visible;
    opacity: 1;
  }

  ${IconWrapper}.dots {
    visibility: ${(props) =>
      props.isCollapsed || props.isProcessingHistory ? "hidden" : "visible"};
    opacity: ${(props) =>
      props.isCollapsed || props.isProcessingHistory ? "0" : "1"};
    transition: all 0.3s ease;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const HistoryItem = ({
  history,
  isActive,
  isCollapsed,
  onHistoryClick,
  clients,
  onAssignClient,
  onRefresh,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const titleRef = useRef(null);
  const menuRef = useRef(null);
  const [isTruncated, setIsTruncated] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [status, setStatus] = useState("pending");

  const getMessage = (stage) => {
    switch (stage) {
      case "uploading":
        return {
          heading: "Uploading",
          subheading: "Uploading audio...",
        };
      case "Processing chunk transcription for recording":
        return {
          heading: "Processing",
          subheading: "Processing your audio recording...",
        };
      case "Combining chunks":
        return {
          heading: "Processing",
          subheading: "Combining audio chunks...",
        };
      case "Uploading combined file to S3":
        return {
          heading: "Processing",
          subheading: "Uploading processed audio...",
        };
      case "Uploading combined file for transcription":
        return {
          heading: "Processing",
          subheading: "Preparing for transcription...",
        };
      case "Starting Lemur transcription":
        return {
          heading: "Transcribing",
          subheading: "Starting the transcription process...",
        };
      case "Updating transcription details":
        return {
          heading: "Transcribing",
          subheading: "Transcribing your audio...",
        };
      case "Waiting for webhook":
        return {
          heading: "Creating thread",
          subheading: "Almost done! Initializing thread...",
        };
      case "Transcription completed":
        return {
          heading: "Creating thread",
          subheading: "Creating a thread for your transcription...",
        };
      case "Created thread":
        return {
          heading: "Creating thread",
          subheading: "Thread created, getting results...",
        };
      case "completed":
        return {
          heading: "Done!",
          subheading: "Your transcription is ready!",
        };
      default:
        return {
          heading: "Processing",
          subheading:
            "Processing your audio... optionally, you can leave this page and come back later.",
        };
    }
  };
  const isProcessingHistory = history.isProcessingHistory;

  useEffect(() => {
    if (titleRef.current) {
      const titleWidth = titleRef.current.scrollWidth;
      setIsTruncated(titleWidth > 179);
    }
  }, [history.title]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    if (showMenu) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showMenu]);

  useEffect(() => {
    const socket = window.store.socket;

    if (isProcessingHistory && history._id && socket) {
      const processingHistoryRoom = `processingHistory_${history._id}`;
      socket.emit("join-room", processingHistoryRoom);

      const handleProgressUpdate = (data) => {
        if (data.processingHistoryId === history._id) {
          setStatus(getMessage(data.stage).subheading);
        }
      };

      const handleError = (error) => {
        if (error.processingHistoryId === history._id) {
          setStatus("error");
        }
      };

      socket.on("transcription-progress", handleProgressUpdate);
      socket.on("transcription-error-v2", handleError);

      return () => {
        socket.emit("leave-room", processingHistoryRoom);
        socket.off("transcription-progress", handleProgressUpdate);
        socket.off("transcription-error-v2", handleError);
      };
    }
  }, [isProcessingHistory, history._id, window.store.socket]);

  const handleDelete = async () => {
    try {
      const response = await store.api.delete(`/histories/${history._id}`);
      if (response.status === 200) {
        await onRefresh();
      }
    } catch (error) {
      console.error("Error deleting history:", error);
    } finally {
      setIsDeleteModalOpen(false);
    }
  };

  const handleUnassign = async (e) => {
    e.stopPropagation();
    try {
      await store.api.put(`/histories/${history._id}/unassign-client`);
      await onRefresh();
      setShowMenu(false);
    } catch (error) {
      console.error("Error unassigning history:", error);
    }
  };

  const hasValidClientAssignment = () => {
    return (
      history.assignedToClient &&
      history.assignedToClient !== "unassigned" &&
      history.assignedToClient._id
    );
  };

  const handleDeleteProcessingHistory = async (processingHistoryId) => {
    try {
      await deleteProcessingHistory(processingHistoryId);
      await onRefresh();
    } catch (error) {
      console.error("Error deleting processing history:", error);
    }
  };

  return (
    <>
      <HistoryItemWrapper
        onClick={() => !isProcessingHistory && onHistoryClick(history.threadId)}
        isActive={isActive}
        isCollapsed={isCollapsed}
        showMenu={showMenu}
        isProcessingHistory={isProcessingHistory}
      >
        <ItemContent>
          <IconWrapper>
            {isProcessingHistory ? (
              history.isError ? (
                <XCircleIcon className="w-6 h-6 text-red-500" />
              ) : (
                <ProcessingHistoryLoader />
              )
            ) : (
              <ChatIcon className="w-6 h-6 text-gray-500" />
            )}
          </IconWrapper>
          <TitleWrapper>
            <h3 ref={titleRef} className={isTruncated ? "truncate" : ""}>
              {history.title || "Untitled"}
            </h3>
            <p>
              {isProcessingHistory && status !== "pending"
                ? status
                : moment(history.created).format("MMM DD, YYYY h:mm A")}
            </p>
            {isProcessingHistory && history.isError && (
              <button
                className="mt-2 flex items-center justify-center w-full px-3 py-1.5 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                onClick={async (e) => {
                  e.stopPropagation();
                  const response = await retranscribeDraft(
                    history._id
                  );

                  if (response.status === 200) {
                    await onRefresh();
                  }
                }}
              >
                <RefreshIcon className="w-4 h-4 mr-2" />
                Re-transcribe
              </button>
            )}
          </TitleWrapper>
        </ItemContent>
        {!isProcessingHistory && (
          <IconWrapper className="dots">
            <DotsVerticalIcon
              className="w-6 h-6"
              onClick={(e) => {
                e.stopPropagation();
                setShowMenu(!showMenu);
              }}
            />
            {showMenu && (
              <ActionMenu ref={menuRef}>
                {hasValidClientAssignment() && (
                  <>
                    <MenuButton onClick={handleUnassign}>
                      <UserRemoveIcon className="w-4 h-4" />
                      Unassign from client
                    </MenuButton>
                    <MenuDivider />
                  </>
                )}
                <MenuButton
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsDeleteModalOpen(true);
                    setShowMenu(false);
                  }}
                >
                  <TrashIcon className="w-4 h-4" />
                  Delete
                </MenuButton>
                <MenuDivider />
                <div className="px-3 py-1 text-xs text-gray-500">
                  Assign to:
                </div>
                {clients.map((client) => (
                  <MenuButton
                    key={client._id}
                    onClick={(e) => {
                      e.stopPropagation();
                      onAssignClient(history._id, client._id);
                      setShowMenu(false);
                    }}
                  >
                    {client.name}
                    {history.assignedToClient?._id === client._id && (
                      <CheckIcon className="w-4 h-4 ml-auto" />
                    )}
                  </MenuButton>
                ))}
              </ActionMenu>
            )}
          </IconWrapper>
        )}
        {isProcessingHistory && (
          <IconWrapper className="dots">
            <DotsVerticalIcon
              className="w-6 h-6"
              onClick={(e) => {
                e.stopPropagation();
                setShowMenu(!showMenu);
              }}
            />
            {showMenu && (
              <ActionMenu ref={menuRef}>
                <MenuButton
                  onClick={async (e) => {
                    e.stopPropagation();
                    await handleDeleteProcessingHistory(history._id);
                  }}
                >
                  <TrashIcon className="w-4 h-4" />
                  Delete
                </MenuButton>
              </ActionMenu>
            )}
          </IconWrapper>
        )}
      </HistoryItemWrapper>

      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={handleDelete}
      />
    </>
  );
};

const ItemContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
`;

const DeleteButton = styled.button`
  position: absolute;
  top: 30px;
  right: 0;
  background-color: white;
  border-radius: 8px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  visibility: visible;
  opacity: 1;
  transition:
    visibility 0s,
    opacity 0.3s ease;

  &:hover {
    background-color: #f3f4f6;
  }

  .w-4 {
    margin-right: 5px;
  }
`;

const DeleteConfirmationModal = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      className="fixed z-10 inset-0 overflow-y-auto"
    >
      <div className="flex items-center justify-center min-h-screen">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
        <div className="relative bg-white rounded-lg p-6 max-w-sm w-full mx-4">
          <Dialog.Title className="text-lg font-medium mb-4">
            Confirm Delete
          </Dialog.Title>
          <div className="mb-6">
            Are you sure you want to delete this history item?
          </div>
          <div className="flex justify-end gap-2">
            <button
              onClick={onClose}
              className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded"
            >
              Cancel
            </button>
            <button
              onClick={onConfirm}
              className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

const TabContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  position: relative;
  margin-bottom: 10px;
`;

const TabButton = styled.button`
  padding: 6px 12px;
  border-radius: 20px;
  background-color: ${(props) => (props.active ? "#5c4bdb" : "transparent")};
  color: ${(props) => (props.active ? "white" : "#333")};
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 12px;

  &:hover {
    background-color: ${(props) => (props.active ? "#4a3db0" : "#f0f0f0")};
  }
`;

const AddClientButton = styled.button`
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background-color: transparent;
  border: 1px solid #e5e7eb;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #4c1d95;

  &:hover {
    background-color: #e5e7eb;
  }
`;

const Section = styled.div`
  margin-bottom: 20px;
  width: 100%;
`;

const SectionTitle = styled.h3`
  font-size: 15px;
  font-weight: 600;
  color: #1f2937;
  padding-left: 4px;
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 0;

  span {
    font-size: 13px;
    font-weight: normal;
    color: #6b7280;
  }
`;

const ActionMenu = styled.div`
  position: absolute;
  top: 30px;
  right: 0;
  background-color: white;
  border-radius: 8px;
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  box-shadow:
    0 4px 6px rgba(0, 0, 0, 0.1),
    0 1px 3px rgba(0, 0, 0, 0.08);
  min-width: 180px;
  z-index: 9999;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #333;
  backdrop-filter: blur(8px);
  background: white;
`;

const MenuButton = styled.button`
  padding: 8px 12px;
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
  background-color: white;
  border: none;
  cursor: pointer;
  position: relative;
  color: #333;

  &:hover {
    background-color: #f3f4f6;
  }

  svg {
    margin-right: 8px;
    color: #666;
  }
`;

const MenuDivider = styled.div`
  height: 1px;
  background-color: #e5e7eb;
  margin: 4px 0;
`;

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
  margin-bottom: 12px;
  position: relative;
  z-index: ${(props) => (props.isMenuOpen ? 1002 : 1)};
`;

const ClientNameContainer = styled.div`
  background-color: #f5f3ff;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  z-index: ${(props) => (props.isMenuOpen ? 1002 : 1)};
  cursor: pointer;

  &:hover {
    background-color: #ede9fe;
  }
`;

const ClientNameSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-right: 12px;
`;

const ClientContainer = styled.div`
  background-color: white;
  border-radius: 12px;
  overflow: visible;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  position: relative;
`;

const HistoriesContainer = styled.div`
  padding: 12px;
  background-color: #f8fafc;
  border-top: 1px solid #e5e7eb;
`;

const SearchContainer = styled.div`
  margin-bottom: 16px;
  padding: 0 2px;
  width: 100%;
`;

const BackgroundLoadingIndicator = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 2px;
  background-color: rgba(92, 75, 219, 0.1);
  z-index: 100;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 30%;
    background-color: #5c4bdb;
    animation: loading 1.5s infinite;
    border-radius: 2px;
  }

  @keyframes loading {
    0% {
      left: -30%;
    }
    50% {
      left: 30%;
    }
    100% {
      left: 100%;
    }
  }
`;

export default HistoryPanel;
