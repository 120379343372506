import React from "react";
import clsx from "clsx";

const OutlineButton = ({
  children,
  onClick,
  disabled = false,
  loading = false,
  className = "",
  size = "md", // sm, md, lg
}) => {
  const baseClasses =
    "inline-flex items-center justify-center rounded-md border font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2";

  const sizeClasses = {
    sm: "px-3 py-1.5 text-sm",
    md: "px-4 py-2 text-sm",
    lg: "px-6 py-3 text-base",
  };

  const stateClasses =
    disabled || loading
      ? "border-gray-200 bg-gray-50 text-gray-400 cursor-not-allowed"
      : "border-gray-900 bg-white text-gray-900 hover:bg-gray-50";

  return (
    <button
      onClick={onClick}
      disabled={disabled || loading}
      className={clsx(baseClasses, sizeClasses[size], stateClasses, className)}
    >
      {loading ? (
        <>
          <svg
            className="animate-spin -ml-1 mr-2 h-4 w-4 text-gray-900"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
          Loading...
        </>
      ) : (
        children
      )}
    </button>
  );
};

export default OutlineButton;
