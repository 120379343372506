const api = `/histories/navix-scribe`;
import { navixScribeV2Store } from "./NavixScribeV2Store";

const logResponse = (response) => {
  console.log("Response:", response);
};

// Fetch personas
export async function fetchPersonas() {
  const res = await store.api.get("/personas");
  return res.data;
}

// Fetch thread histories
export async function fetchThreadHistories() {
  const res = await store.api.get(`${api}/thread-histories`);
  return res.data;
}

// Fetch thread details
export async function fetchThreadDetails(threadId) {
  const res = await store.api.get(`/threads/${threadId}`);
  return res.data;
}

// Fetch messages for a thread
export async function fetchMessages(threadId) {
  const res = await store.api.get(`/threads/${threadId}/messages`);
  return res.data;
}

export async function fetchHistories(
  currentPage = 1,
  toolType,
  toolAction,
  activeButton
) {
  const res = await store.api.get(
    `${api}?page=${currentPage}&toolType=${toolType}&toolAction=${toolAction}&isArchived=${activeButton}`
  );
  return res.data;
}

// Get history
export async function getHistory(id) {
  const res = await store.api.get(`/histories/navix-scribe/${id}`);
  return res.data;
}

// Update history
export async function updateHistory(id, payload) {
  try {
    const response = await store.api.put(
      `/histories/navix-scribe/${id}`,
      payload
    );
    console.log("History update response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error updating history:", error);
    throw error;
  }
}

//#region Input Tool API

// Transcribe audio
export async function transcribeAudio(
  file,
  audioDuration,
  durationString,
  options = {}
) {
  try {
    if (options.isChunked) {
      const payload = {
        recordingId: options.recordingId,
        audioDuration,
        audioDurationString: durationString,
        draftId: options.draftId,
        assignedToClient: options.assignedToClient || null,
      };

      const response = await store.api.post(
        "/ai/v2/audio/transcribe-chunks-v2",
        payload
      );
      logResponse(response);
      return response;
    } else {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("audioDuration", audioDuration);
      formData.append("audioDurationString", durationString);
      formData.append("draftId", options.draftId);

      if (options.assignedToClient) {
        formData.append("assignedToClient[_id]", options.assignedToClient._id);
        formData.append(
          "assignedToClient[name]",
          options.assignedToClient.name
        );
        formData.append(
          "assignedToClient[status]",
          options.assignedToClient.status
        );
      }

      const response = await store.api.post(
        "/ai/v2/audio/transcribe-audio-v2",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      logResponse(response);
      return response;
    }
  } catch (error) {
    console.error("Error in transcribeAudio:", error);
    throw error;
  }
}

export async function retranscribeDraft(processingHistoryId) {
  const response = await store.api.post(
    `/ai/v2/audio/retranscribe/${processingHistoryId}`
  );
  logResponse(response);
  return response;
}

export async function waitForTranscription(
  transcriptId,
  timeout = 600000,
  interval = 5000
) {
  const endTime = Date.now() + timeout;

  const checkStatus = async () => {
    try {
      const response = await store.api.get(
        `/histories/navix-scribe/${transcriptId}`
      );
      const data = response.data;
      if (data.history.audioTranscriber.status === "done") {
        return data.history;
      } else if (data.history.audioTranscriber.status === "error") {
        throw new Error("Transcription failed.");
      } else if (Date.now() < endTime) {
        await new Promise((resolve) => setTimeout(resolve, interval));
        return checkStatus();
      } else {
        throw new Error("Transcription timed out.");
      }
    } catch (error) {
      console.error("Error checking transcription status:", error);
      throw error;
    }
  };

  return checkStatus();
}

// Save text
export async function saveText(text, file = null) {
  const formData = new FormData();
  formData.append("text", text);

  if (file && file.type === "application/pdf") {
    formData.append("file", file);
  }

  try {
    const response = await store.api.post(
      "/ai/navixscribe/input/save-text",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    logResponse(response);
    return response;
  } catch (error) {
    console.error("Error in saveText:", error);
    throw error;
  }
}

// Retranscribe audio
export async function retranscribeAudio(transcriptId) {
  const response = await store.api.post(
    `/ai/audio/manual-retranscribe/${transcriptId}`
  );
  logResponse(response);
  return response;
}
//#endregion

// Check account status
export async function checkAccountStatus() {
  const response = await store.api.get("/user/status");
  logResponse(response);
  return response.data;
}

/**
 * Streams an audio chunk to the server
 * @param {FormData} formData - Contains: chunk (Blob), recordingId (string), isFinal (boolean)
 * @returns {Promise<Object>} Response from the server
 */
export async function streamAudioChunk(formData) {
  try {
    const response = await store.api.post(
      "/ai/v2/audio/stream-chunk",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        // Add timeout and retry config
        timeout: 10000, // 10 second timeout
        retries: 3,
        retryDelay: 1000,
      }
    );

    logResponse(response);
    return response.data;
  } catch (error) {
    console.error("Error streaming audio chunk:", error);
    // Check if it's a timeout error
    if (error.code === "ECONNABORTED") {
      throw new Error("Upload timeout - chunk too large or network too slow");
    }
    throw error;
  }
}

//#region Streaming Audio API

/**
 * Saves a recording to drafts
 * @param {string} recordingId - The ID of the recording
 * @returns {Promise<Object>} Response from the server
 */
export async function saveRecordingToDrafts(recordingId) {
  try {
    const response = await store.api.post("/ai/v2/audio/save-draft", {
      recordingId,
    });
    logResponse(response);
    return response.data;
  } catch (error) {
    console.error("Error saving recording to drafts:", error);
    throw error;
  }
}

/**
 * Gets the status of a recording
 * @param {string} recordingId - The ID of the recording
 * @returns {Promise<Object>} Recording status and metadata
 */
export async function getRecording(recordingId) {
  try {
    const response = await store.api.get(
      `/ai/v2/audio/recording/${recordingId}`
    );
    logResponse(response);
    return response.data;
  } catch (error) {
    console.error("Error getting recording status:", error);
    throw error;
  }
}

/**
 * Gets all recordings for the current user
 * @returns {Promise<Object>} List of recordings
 */
export async function getRecordings() {
  try {
    const response = await store.api.get("/ai/v2/audio/recording-drafts");
    logResponse(response);
    return response.data;
  } catch (error) {
    console.error("Error getting recordings:", error);
    throw error;
  }
}

/**
 * Retries uploading failed chunks
 * @param {string} recordingId - The ID of the recording
 * @param {Array<number>} chunkIndexes - Array of failed chunk indexes to retry
 * @returns {Promise<Object>} Response from the server
 */
export async function retryFailedChunks(recordingId, chunkIndexes) {
  try {
    const response = await store.api.post("/ai/v2/audio/retry-chunks", {
      recordingId,
      chunkIndexes,
    });
    logResponse(response);
    return response.data;
  } catch (error) {
    console.error("Error retrying failed chunks:", error);
    throw error;
  }
}

/**
 * Cancels an ongoing recording
 * @param {string} recordingId - The ID of the recording to cancel
 * @returns {Promise<Object>} Response from the server
 */
export async function cancelRecording(recordingId) {
  try {
    const response = await store.api.post(
      `/ai/v2/audio/cancel-recording/${recordingId}`
    );
    logResponse(response);
    return response.data;
  } catch (error) {
    console.error("Error canceling recording:", error);
    throw error;
  }
}

// Delete a recording
export async function deleteRecording(recordingId) {
  const response = await store.api.delete(
    `/ai/v2/audio/recording/${recordingId}`
  );
  logResponse(response);
  return response.data;
}

// Update a recording title
export async function updateRecordingTitle(recordingId, title) {
  const response = await store.api.put(
    `/ai/v2/audio/recording/${recordingId}/title`,
    {
      title,
    }
  );
  logResponse(response);
  return response.data;
}
//#endregion
