const api = `/clients`;

export const createClient = async (name) => {
  try {
    const response = await store.api.post(api, {
      name,
      userId: store.profile._id,
    });
    return response.data.result;
  } catch (error) {
    console.error(`Error creating client: ${error.message}`);
    throw error;
  }
};

export const getClients = async (params = {}) => {
  try {
    const queryParams = new URLSearchParams();
    if (params.search) queryParams.append("search", params.search);
    if (params.sortBy) queryParams.append("sortBy", params.sortBy);
    if (params.sortOrder) queryParams.append("sortOrder", params.sortOrder);
    if (params.status) queryParams.append("status", params.status);

    const queryString = queryParams.toString();
    const url = queryString ? `${api}?${queryString}` : api;

    const response = await store.api.get(url);
    return response.data;
  } catch (error) {
    console.error(`Error fetching clients: ${error.message}`);
    throw error;
  }
};

export const updateClientStatus = async (clientId, status) => {
  try {
    const response = await store.api.put(`${api}/${clientId}/status`, {
      status,
    });
    return response.data;
  } catch (error) {
    console.error(`Error updating client status: ${error.message}`);
    throw error;
  }
};

export const deleteClient = async (clientId) => {
  try {
    const response = await store.api.delete(`${api}/${clientId}`);
    return response.data;
  } catch (error) {
    console.error(`Error deleting client: ${error.message}`);
    throw error;
  }
};

export const assignThreadToClient = async (threadId, clientId) => {
  try {
    const response = await store.api.put(
      `/histories/${threadId}/assign-client`,
      {
        clientId,
      }
    );
    return response.data;
  } catch (error) {
    console.error(`Error assigning thread to client: ${error.message}`);
    throw error;
  }
};

export const updateClientName = async (clientId, name) => {
  try {
    const response = await store.api.put(`${api}/${clientId}/name`, {
      name,
    });
    return response.data;
  } catch (error) {
    console.error(`Error updating client name: ${error.message}`);
    throw error;
  }
};
