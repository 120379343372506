import React, { useEffect, useRef, useState } from "react";
import { observer, inject } from "mobx-react";
import { convertAudioFromUrl } from "../tools/audio/audioUtils";
import styled from "styled-components";

const EntryAudio = inject("store")(
  observer(({ store, audioUrl, getAudioDuration, audioFile }) => {
    const audioRef = useRef(null);
    const [start, end] = store.audioTimeStamp;
    const { isPlaying } = store.audioStatus;
    const [isLoading, setIsLoading] = useState(false);

    const handleFileUploadAndDownload = async () => {
      if (
        !audioUrl ||
        typeof audioUrl !== "string" ||
        !(audioUrl.startsWith("http") || audioUrl.startsWith("https"))
      ) {
        console.error("No valid audio URL available for download.");
        return;
      }
      setIsLoading(true);
      try {
        let convertedAudioUrl;
        convertedAudioUrl = await convertAudioFromUrl(audioUrl, store);
        const response = await fetch(convertedAudioUrl);
        const blob = await response.blob();

        const date = new Date();
        const filename = `output-audio-${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}_${date.getHours()}-${date.getMinutes()}-${date.getSeconds()}.mp3`;

        const blobUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(blobUrl);
      } catch (error) {
        console.error("Error during file download:", error);
      } finally {
        setIsLoading(false);
      }
    };

    useEffect(() => {
      if (audioRef.current) {
        const audioElement = audioRef.current;
        const handleLoadedMetadata = () => {
          getAudioDuration(audioElement.duration);
        };

        audioElement.addEventListener("loadedmetadata", handleLoadedMetadata);

        return () => {
          audioElement.removeEventListener(
            "loadedmetadata",
            handleLoadedMetadata
          );
        };
      }
    }, [audioUrl]);

    useEffect(() => {
      try {
        if (audioRef.current && start !== undefined && end !== undefined) {
          const audioElement = audioRef.current;
          audioElement.currentTime = start;

          if (isPlaying) audioElement.play();
          else audioElement.pause();

          const handleTimeUpdate = () => {
            if (audioElement.currentTime >= end) {
              audioElement.pause();
              store.setAudioStatus({ isPlaying: false });
              store.removeAudioTimeStamp();
            }
          };

          audioElement.addEventListener("timeupdate", handleTimeUpdate);

          return () => {
            audioElement.removeEventListener("timeupdate", handleTimeUpdate);
          };
        }
      } catch (err) {
        console.log(err);
      }
    }, [start, end, isPlaying]);

    return (
      <div className="w-full">
        <audio
          ref={audioRef}
          controls
          controlsList="nodownload"
          src={audioUrl}
          preload="metadata"
          className="w-full my-2"
        />
        <div
          className="flex justify-end"
          style={{
            display:
              audioUrl &&
              (audioUrl.startsWith("http") || audioUrl.startsWith("https"))
                ? "flex"
                : "none",
          }}
        >
          <DownloadButton
            onClick={handleFileUploadAndDownload}
            disabled={isLoading}
          >
            {isLoading ? <Spinner /> : <DownloadIcon />}
            Download
          </DownloadButton>
        </div>
      </div>
    );
  })
);

const DownloadButton = styled.button`
  border-radius: 5.89px;
  background-color: #f0f3f4;
  padding: 0.5rem 2rem;
  color: #4336ab;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-left: auto;
  margin-top: 10px;
  width: auto;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0.75rem 1rem;
    font-size: 14px;
  }

  &:hover {
    background-color: #d7cbff;
  }

  &:active {
    background-color: #9d80ff;
    transform: scale(0.98);
  }

  &:disabled {
    cursor: not-allowed;
    background-color: #451e78;
  }
`;

const Spinner = styled.div`
  border: 2px solid #f3f3f3;
  border-top: 2px solid #4336ab;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const DownloadIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4 6L8 10L12 6H9V2H7V6H4Z" />
    <path d="M2 14V12H14V14H2Z" />
  </svg>
);

export default EntryAudio;
