import React from "react";
import styled, { keyframes } from "styled-components";

const ProcessingHistoryLoader = () => {
  return (
    <SpinnerContainer>
      <Spinner />
    </SpinnerContainer>
  );
};

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
  width: 24px;
  height: 24px;
  border: 3px solid #4c1d95;
  border-top: 3px solid #bebebe;
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
`;

export default ProcessingHistoryLoader;
