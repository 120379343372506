// Frontend Types
export const VOBTypes = {
  INSTANT: "instant",
  LIVE: "live",
};

export const VOBStatus = {
  ACTIVE: "active",
  ARCHIVED: "archived",
  PENDING: "pending",
};

export const CoverageTypes = {
  INDIVIDUAL: "individual",
  FAMILY: "family",
};

// Frontend Models
export class VOB {
  constructor(data = {}) {
    this.id = data.id || null;
    this.controlNo = data.controlNo || "";
    this.location = data.location || "";
    this.npi = data.npi || "";
    this.firstName = data.firstName || "";
    this.lastName = data.lastName || "";
    this.memberId = data.memberId || "";
    this.dateOfBirth = data.dateOfBirth || "";
    this.gender = data.gender || "";
    this.ssn = data.ssn || "";
    this.idCard = data.idCard || "";
    this.dependents = (data.dependents || []).map((d) => new Dependent(d));
    this.type = data.type || VOBTypes.INSTANT;
    this.status = data.status || VOBStatus.ACTIVE;
    this.lastFetched = data.lastFetched || null;
    this.benefits = data.benefits ? new Benefits(data.benefits) : null;
  }
}

export class Dependent {
  constructor(data = {}) {
    this.id = data.id || null;
    this.firstName = data.firstName || "";
    this.lastName = data.lastName || "";
    this.gender = data.gender || "";
    this.dateOfBirth = data.dateOfBirth || "";
    this.groupNo = data.groupNo || "";
    this.relationship = data.relationship || "";
  }
}

export class Benefits {
  constructor(data = {}) {
    this.provider = {
      organizationName: data.provider?.organizationName || "",
      npi: data.provider?.npi || "",
      serviceProviderNo: data.provider?.serviceProviderNo || "",
      referenceIdentification: data.provider?.referenceIdentification || "",
    };

    this.subscriber = {
      memberId: data.subscriber?.memberId || "",
      firstName: data.subscriber?.firstName || "",
      lastName: data.subscriber?.lastName || "",
      dob: data.subscriber?.dob || "",
      ssn: data.subscriber?.ssn || "",
    };

    this.dependents = (data.dependents || []).map((d) => new Dependent(d));

    this.providerInformation = {
      providerName: data.providerInformation?.providerName || "",
      npi: data.providerInformation?.npi || "",
    };

    this.payerInformation = {
      name: data.payerInformation?.name || "",
      payerId: data.payerInformation?.payerId || "",
    };

    this.planInformation = {
      startDate: data.planInformation?.startDate || "",
      type: data.planInformation?.type || "",
      coverage: data.planInformation?.coverage || "",
      planNumber: data.planInformation?.planNumber || "",
      groupNumber: data.planInformation?.groupNumber || "",
    };

    this.coverageDetails = {
      medicalCare: {
        coverageLevel: data.coverageDetails?.medicalCare?.coverageLevel || "",
        lifetimeBenefitMaximum:
          data.coverageDetails?.medicalCare?.lifetimeBenefitMaximum || 0,
        lifetimeRemainingBenefit:
          data.coverageDetails?.medicalCare?.lifetimeRemainingBenefit || 0,
      },
    };

    this.lastFetched = data.lastFetched || new Date();
  }
}

export class LiveVOB {
  constructor() {
    // Header
    this.assignedTo = "";
    this.location = "";

    // Client Info
    this.firstName = "";
    this.lastName = "";
    this.dateOfBirth = "";
    this.gender = "";
    this.subscriberName = "";
    this.subscriberDateOfBirth = "";
    this.relationshipToClient = "";
    this.representativeName = "";
    this.employerName = "";
    this.policyType = ""; // employer or individual

    // Contact/Policy Info
    this.addressOnPolicy = "";
    this.timeOfVOB = "";
    this.dateOfVOB = "";
    this.callReferenceNo = "";
    this.preCertPhoneNo = "";
    this.payerName = "";
    this.insurancePhoneNo = "";
    this.memberNo = "";
    this.groupNo = "";
    this.bhCarveOut = "";
    this.carveOutIdNo = "";

    // Coverage Details
    this.effectiveDate = "";
    this.planFunding = ""; // self or full
    this.activeCoverage = true;

    // Reimbursement Details
    this.aobAccepted = false;
    this.oonAllowedAmount = false;
    this.medicareRates = false;
    this.usualAndCustomary = false;
    this.priceByLocalPlan = false;

    // Level of Care
    this.levelOfCare = {
      dtx: false,
      rtc: false,
      php: false,
      adTxPerDiem: false,
      iopCD: false,
      iopMH: false,
      groupPsychotherapy: false,
      familyTherapy: false,
      psychotherapyForCrisis: false,
      indPsychotherapy: false,
    };

    // Pre-Certification
    this.preCertification = {
      dtx: false,
      rtc: false,
      php: false,
      iop: false,
      op: false,
      nonRoutineOp: false,
    };
    this.cobOnFile = {
      lastUpdated: "",
      hasOtherInsurance: false,
      carrier: "",
    };

    // Insurance Specific
    this.aetna = {
      feeSchedule: false,
      reasonableCharges: false,
      fiftyNonPar: false,
      nationalAdvantageProgram: false,
    };
    this.cigna = {
      mhc1: false,
      mhc2: false,
      averageContractedRate: false,
    };
    this.blue = {
      blueCross: false,
      blueShield: false,
    };

    // Plan Level Details
    this.planLevel = {
      coInsurance: "",
      afterOOP: "",
      indDeductible: "",
      familyDeductible: "",
      inOutOfPocket: "",
      familyInOutOfPocket: "",
      isDeductibleIncludedInOOPM: false,
      coPays: "",
      admitFee: "",
      dollarDayMax: "",
      visitLimits: "",
      loc: "",
      rem: "",
      claimsMailing: "",
      payerId: "",
      accreditation: "",
    };

    // Pharmacy Details
    this.pharmacy = {
      rxBenefitAdmin: "",
      rxPhoneNo: "",
      rxBin: "",
      pcn: "",
      rxGroup: "",
    };

    this.additionalNotes = "";
  }
}
