import React from "react";
import { observer } from "mobx-react";
import { vobStore } from "../VOBStore";
import {
  ChevronUpIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  DotsVerticalIcon,
} from "@heroicons/react/solid";
import { Menu, Transition } from "@headlessui/react";
import { format } from "date-fns";
import clsx from "clsx";
import OutlineButton from "./OutlineButton";

const VOBTable = observer(() => {
  const { vobs, loading, pagination, sortConfig } = vobStore;

  const handleSort = (field) => {
    const direction =
      sortConfig.field === field && sortConfig.direction === "asc"
        ? "desc"
        : "asc";
    vobStore.setSortConfig({ field, direction });
    vobStore.fetchVOBs();
  };

  const handlePageChange = (page) => {
    vobStore.setPagination({ currentPage: page });
    vobStore.fetchVOBs();
  };

  const renderSortIcon = (field) => {
    if (sortConfig.field !== field) return null;
    return sortConfig.direction === "asc" ? (
      <ChevronUpIcon className="w-4 h-4" />
    ) : (
      <ChevronDownIcon className="w-4 h-4" />
    );
  };

  const ActionMenu = ({ vob }) => (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button className="inline-flex items-center justify-center w-8 h-8 text-gray-400 hover:text-gray-500">
        <DotsVerticalIcon className="w-5 h-5" aria-hidden="true" />
      </Menu.Button>

      <Transition
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <button
                  className={clsx(
                    active ? "bg-gray-50 text-gray-900" : "text-gray-700",
                    "block w-full text-left px-4 py-2 text-sm"
                  )}
                  onClick={() => {}}
                >
                  Download
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  className={clsx(
                    active ? "bg-gray-50 text-gray-900" : "text-gray-700",
                    "block w-full text-left px-4 py-2 text-sm"
                  )}
                  onClick={() => {}}
                >
                  Edit
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  className={clsx(
                    active ? "bg-gray-50 text-gray-900" : "text-gray-700",
                    "block w-full text-left px-4 py-2 text-sm"
                  )}
                  onClick={() => {}}
                >
                  Send via Email
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  className={clsx(
                    active ? "bg-gray-50 text-red-700" : "text-red-600",
                    "block w-full text-left px-4 py-2 text-sm"
                  )}
                  onClick={() => vobStore.archiveVOB(vob.id)}
                >
                  Archive
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );

  if (loading && !vobs.length) {
    return (
      <div className="p-8 text-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900 mx-auto"></div>
        <p className="mt-4 text-gray-600">Loading VOBs...</p>
      </div>
    );
  }

  return (
    <div className="overflow-hidden">
      <div className="overflow-x-auto">
        <table className="min-w-full table-fixed">
          <thead>
            <tr className="bg-gray-50 border-b border-gray-200">
              <th
                scope="col"
                className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/4 cursor-pointer"
                onClick={() => handleSort("patientName")}
              >
                <div className="flex items-center space-x-1">
                  <span>Identified Patient</span>
                  {renderSortIcon("patientName")}
                </div>
              </th>
              <th
                scope="col"
                className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/6 cursor-pointer"
                onClick={() => handleSort("coverageStatus")}
              >
                <div className="flex items-center space-x-1">
                  <span>Active Coverage</span>
                  {renderSortIcon("coverageStatus")}
                </div>
              </th>
              <th
                scope="col"
                className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/6 cursor-pointer"
                onClick={() => handleSort("payerName")}
              >
                <div className="flex items-center space-x-1">
                  <span>Payer Name</span>
                  {renderSortIcon("payerName")}
                </div>
              </th>
              <th
                scope="col"
                className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/6 cursor-pointer"
                onClick={() => handleSort("instant")}
              >
                <div className="flex items-center space-x-1">
                  <span>Instant</span>
                  {renderSortIcon("instant")}
                </div>
              </th>
              <th
                scope="col"
                className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/6 cursor-pointer"
                onClick={() => handleSort("dateCreated")}
              >
                <div className="flex items-center space-x-1">
                  <span>Date Created</span>
                  {renderSortIcon("dateCreated")}
                </div>
              </th>
              <th scope="col" className="relative px-6 py-4 w-[60px]">
                <span className="sr-only">Actions</span>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {vobs.map((vob) => (
              <tr key={vob.id} className="hover:bg-gray-50">
                <td className="px-6 py-4 text-sm font-medium text-gray-900 truncate">
                  {vob.patientName}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500">
                  {vob.coverageStatus}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500">
                  {vob.payerName}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500">
                  {vob.instant ? "Yes" : "No"}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500">
                  {format(new Date(vob.dateCreated), "MM-dd-yyyy")}
                </td>
                <td className="px-6 py-4 text-right text-sm font-medium">
                  <ActionMenu vob={vob} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200">
        <div className="flex items-center">
          <select
            className="border-gray-300 rounded-md text-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            value={pagination.itemsPerPage}
            onChange={(e) => {
              vobStore.setPagination({ itemsPerPage: Number(e.target.value) });
              vobStore.fetchVOBs();
            }}
          >
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>

        <div className="flex items-center space-x-2">
          <nav
            className="relative z-0 inline-flex -space-x-px"
            aria-label="Pagination"
          >
            <button
              onClick={() => handlePageChange(pagination.currentPage - 1)}
              disabled={pagination.currentPage === 1}
              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            {[...Array(pagination.totalPages)].map((_, i) => (
              <button
                key={i + 1}
                onClick={() => handlePageChange(i + 1)}
                className={clsx(
                  "relative inline-flex items-center px-4 py-2 border text-sm font-medium",
                  pagination.currentPage === i + 1
                    ? "z-10 bg-indigo-50 border-indigo-500 text-indigo-600"
                    : "bg-white border-gray-300 text-gray-500 hover:bg-gray-50"
                )}
              >
                {i + 1}
              </button>
            ))}
            <button
              onClick={() => handlePageChange(pagination.currentPage + 1)}
              disabled={pagination.currentPage === pagination.totalPages}
              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
});

export default VOBTable;
