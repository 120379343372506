import { navixScribeV2Store } from "./NavixScribeV2Store";

const api = `/azure`;

export const createThread = async () => {
  try {
    const response = await store.api.post(`${api}/create-thread`);
    return response.data.result;
  } catch (error) {
    console.error(`Error creating thread: ${error.message}`);
    throw error;
  }
};

export const runAssistant = async (threadId, assistantId, instructions) => {
  try {
    navixScribeV2Store.setIsAwaitingResponse(true);

    const fullInstructions = `##Persona Instructions:##
${navixScribeV2Store.personaPrompt}\n\n

${instructions || ""}\n\n

##Writing Style Instructions:##
	•	${navixScribeV2Store.getNoteStyle() === "Bullet-point" ? "Each section should use bullet points for clarity and easier reading." : "Each section should be in narrative paragraph format, not lists or bullet points."} 
	•	Maintain a natural flow of information within each section.
	•	Use '${navixScribeV2Store.getPronouns()}' for pronouns and replace patient names with '${navixScribeV2Store.getPatientName()}'.
	•	${navixScribeV2Store.getNoteStyle() === "Bullet-point" ? "Ensure bullet points are formatted consistently throughout the document." : "Avoid numbered lists, bullet points, or fragmented formatting."}`;

    const payload = {
      thread_id: threadId,
      assistant_id: assistantId,
      instructions: fullInstructions.trim(),
    };

    const response = await store.api.post(`${api}/run-thread`, payload);
    navixScribeV2Store.setIsAwaitingResponse(false);
    return response.data.result;
  } catch (error) {
    navixScribeV2Store.setIsAwaitingResponse(false);
    console.error(`Error running the assistant: ${error.message}`);
    throw error;
  }
};

export const addMessage = async (
  threadId,
  role,
  content,
  setAwaitingResponse = true
) => {
  try {
    if (setAwaitingResponse) {
      navixScribeV2Store.setIsAwaitingResponse(true);
    }
    const response = await store.api.post(`${api}/add-message`, {
      thread_id: threadId,
      role,
      content,
    });
    if (setAwaitingResponse) {
      navixScribeV2Store.setIsAwaitingResponse(false);
    }
    navixScribeV2Store.emitScrollToBottom();
    return response.data.result;
  } catch (error) {
    if (setAwaitingResponse) {
      navixScribeV2Store.setIsAwaitingResponse(false);
    }
    console.error(`Error adding message: ${error.message}`);
    throw error;
  }
};

export const fetchMessages = async (threadId) => {
  try {
    const response = await store.api.get(`${api}/get-messages/${threadId}`);
    if (response.data.awsUrl) {
      navixScribeV2Store.setAwsUrl(response.data.awsUrl);
    }
    if (response.data.audioDuration) {
      navixScribeV2Store.setAudioDuration(response.data.audioDuration);
    }
    return response.data.result.data || [];
  } catch (error) {
    console.error(`Error fetching messages: ${error.message}`);
    throw error;
  }
};

export const uploadFile = async (file) => {
  try {
    const formData = new FormData();
    formData.append("file", file, file.name);

    const response = await store.api.post(`${api}/upload-file`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data.file_id;
  } catch (error) {
    console.error(`Error uploading file:`, error);
    if (error.response) {
      console.error("Error response:", error.response.data);
    }
    throw error;
  }
};

export const attachFileToThread = async (threadId, fileId, content) => {
  try {
    const response = await store.api.post(`${api}/attach-file-to-thread`, {
      thread_id: threadId,
      file_id: fileId,
      content,
    });
    return response.data.message_id;
  } catch (error) {
    console.error(`Error attaching file to thread: ${error.message}`);
    throw error;
  }
};

export const addFileToThread = async (threadId, file) => {
  try {
    const fileId = await uploadFile(file);
    const messageId = await attachFileToThread(
      threadId,
      fileId,
      `File attached: ${file.name}`
    );
    return { fileId, messageId };
  } catch (error) {
    console.error(`Error adding file to thread: ${error.message}`);
    throw error;
  }
};
