import config from "../../../config";

// Import store for API usage
const api = `/vob/optum`;

class VOBService {
  constructor() {
    // Debug environment variables
    console.log("Environment Variables Debug:");
    console.log("REACT_APP_ENVIRONMENT:", process.env.REACT_APP_ENVIRONMENT);
    console.log("NODE_ENV:", process.env.NODE_ENV);
    console.log("Config:", config);
  }

  // Optum API Methods
  async checkEligibility(data) {
    try {
      const response = await store.api.post(`${api}/eligibility`, data);
      return response.data;
    } catch (error) {
      console.error("Error checking eligibility:", error);
      throw error;
    }
  }

  async getBenefits(data) {
    try {
      const optumResponse = await this.checkEligibility(data);
      return optumResponse.result;
    } catch (error) {
      console.error("Error in getBenefits:", error);
      throw error;
    }
  }

  // Instant VOB API Endpoints
  async getInstantBenefits(data) {
    return store.api.post("/vob/instant/benefits", data);
  }

  async createInstantVOB(data) {
    return store.api.post("/vob/instant", data);
  }

  // Estimates VOB API Endpoints
  async getEstimates(data) {
    return store.api.post("/vob/estimates/benefits", data);
  }

  async createEstimatesVOB(data) {
    return store.api.post("/vob/estimates", data);
  }

  // Common VOB Endpoints
  async getVOBs(params) {
    const {
      page = 1,
      limit = 25,
      sort = "dateCreated",
      order = "desc",
      search = "",
    } = params;
    const response = await store.api.get("/vob", {
      params: {
        page,
        limit,
        sort,
        order,
        search,
      },
    });
    return response.data;
  }

  async getVOBById(id) {
    const response = await store.api.get(`/vob/${id}`);
    return response.data;
  }

  async updateVOB(id, data) {
    const response = await store.api.put(`/vob/${id}`, data);
    return response.data;
  }

  async archiveVOB(id) {
    const response = await store.api.delete(`/vob/${id}`);
    return response.data;
  }

  async exportVOBPDF(id) {
    const response = await store.api.get(`/vob/${id}/export`, {
      responseType: "blob",
    });
    return response.data;
  }

  async emailVOBPDF(id, email, message) {
    const response = await store.api.post(`/vob/${id}/email`, {
      email,
      message,
    });
    return response.data;
  }
}

export const vobService = new VOBService();
