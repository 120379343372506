import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { MicrophoneIcon } from "@heroicons/react/solid";
import { inject, observer } from "mobx-react";
import toast from "react-hot-toast";

@inject("store")
@observer
class DownloadDesktop extends Component {
  state = {
    isLoading: true,
    windowsVersion: null,
    macosVersion: null,
    error: null,
  };

  componentDidMount() {
    this.fetchVersionData();
  }

  fetchVersionData = async () => {
    const { store } = this.props;

    try {
      const { data } = await store.api.get("/version");
      // Find the Windows and macOS versions in the response
      const windowsVersion = data.find((v) => v.platform === "windows");
      const macosVersion = data.find((v) => v.platform === "macos");

      this.setState({
        windowsVersion,
        macosVersion,
        isLoading: false,
      });
    } catch (error) {
      console.error("Error fetching version data:", error);
      this.setState({
        error: "Failed to fetch download links. Please try again later.",
        isLoading: false,
      });
    }
  };

  handleDownload = () => {
    const { store } = this.props;
    const { windowsVersion, macosVersion } = this.state;

    const userAgent = window.navigator.userAgent;
    const isMac = /Mac/.test(userAgent);

    // Use the download URL from the API response
    const downloadUrl = isMac
      ? macosVersion?.downloadUrl ||
        "https://downloads.navixhealth.com/macos/navix_ai_v1.4.0.dmg"
      : windowsVersion?.downloadUrl ||
        "https://downloads.navixhealth.com/windows/navix_ai_v1.4.0.exe";

    // Get filename from URL
    const filename = downloadUrl.split("/").pop();

    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    store.setDesktopAppDownloaded();

    toast.success("Download successfully started!", {
      duration: 3000,
    });
  };

  render() {
    const { isLoading, error } = this.state;

    return (
      <div className="min-h-screen bg-gray-50 py-40 px-4 sm:px-6 lg:px-8">
        <Helmet>
          <title>Download Navix Desktop App</title>
        </Helmet>

        <div className="max-w-md mx-auto text-center">
          <h1 className="text-4xl font-bold text-gray-900 mb-8">
            Download Our Desktop App!
          </h1>

          <div className="bg-white rounded-xl shadow-lg p-16 mb-8 border-2 border-indigo-450">
            <div className="flex justify-center mb-4">
              <div className="bg-indigo-100 p-3 rounded-full">
                <MicrophoneIcon className="h-8 w-8 text-indigo-600" />
              </div>
            </div>

            <h2 className="text-3xl font-semibold text-gray-900 mb-2">
              NavixAI Desktop App
            </h2>

            <p className="text-gray-600 mb-6">Highest quality voice record</p>

            {error && <p className="text-red-500 mb-4">{error}</p>}

            <button
              onClick={this.handleDownload}
              disabled={isLoading}
              className={`w-full border-2 border-indigo-600 text-indigo-600 rounded-full py-2 px-4 hover:bg-indigo-600 hover:text-white transition-colors duration-200 ${isLoading ? "opacity-50 cursor-not-allowed" : ""}`}
            >
              {isLoading ? "Loading..." : "Download Here"}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default DownloadDesktop;
