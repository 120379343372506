import React, { Component } from "react";
import { CheckIcon, UserIcon, LockClosedIcon } from "@heroicons/react/outline";
import { Switch, Route, withRouter, Redirect, NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import { observable, makeObservable } from "mobx";
import toast from "react-hot-toast";
import Select from "react-select";
import { observer, inject } from "mobx-react";
import Logo from "../navix-ai.png";
import videoBg from "../bg-video.webm";
import { useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const shouldSkipCaptcha = () => {
  return (
    process.env.REACT_APP_ENVIRONMENT === "local" ||
    process.env.DISABLE_CAPTCHA === "true"
  );
};

@inject("store")
@observer
class Login extends Component {
  @observable plan = "";
  @observable fname = "";
  @observable lname = "";
  @observable email = "";
  @observable password = "";
  @observable repeatPassword = "";
  @observable errorMessage = "";
  @observable passwordsMatch = true;
  @observable recaptchaToken = "";
  @observable captchaToken = null;
  @observable isRecaptchaLoaded = false;
  @observable isRecaptchaLoading = true;
  @observable phone = "";

  constructor() {
    super();
    makeObservable(this);
  }

  componentDidMount() {
    this.initializeRecaptcha();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.initializeRecaptcha();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("recaptchaLoaded", this.handleRecaptchaLoad);
    this.cleanupRecaptcha();
  }

  cleanupRecaptcha = () => {
    const existingElements = document.getElementsByClassName("g-recaptcha");
    while (existingElements.length > 0) {
      existingElements[0].remove();
    }
    const iframes = document.querySelectorAll('iframe[src*="recaptcha"]');
    iframes.forEach((iframe) => iframe.remove());
  };

  initializeRecaptcha = () => {
    if (shouldSkipCaptcha()) {
      this.isRecaptchaLoading = false;
      return;
    }

    this.isRecaptchaLoading = true;
    this.cleanupRecaptcha();

    if (window.grecaptcha && window.grecaptcha.enterprise) {
      this.isRecaptchaLoading = false;
      this.loadReCaptcha();
    } else {
      window.removeEventListener("recaptchaLoaded", this.handleRecaptchaLoad);
      window.addEventListener("recaptchaLoaded", this.handleRecaptchaLoad);
    }
  };

  handleRecaptchaLoad = () => {
    this.isRecaptchaLoading = false;
    this.loadReCaptcha();
  };

  loadReCaptcha = () => {
    this.cleanupRecaptcha();

    const element = document.createElement("div");
    element.className = "g-recaptcha";
    element.id = `recaptcha-${this.props.location.pathname.replace("/", "")}`;
    element.setAttribute(
      "data-sitekey",
      process.env.REACT_APP_RECAPTCHA_SITE_KEY
    );

    const action =
      this.props.location.pathname === "/login" ? "login" : "signup";
    element.setAttribute("data-action", action);

    const container = document.getElementById("recaptcha-container");
    if (container) {
      container.innerHTML = "";
      container.appendChild(element);

      if (window.grecaptcha && window.grecaptcha.enterprise) {
        window.grecaptcha.enterprise.ready(() => {
          try {
            window.grecaptcha.enterprise.render(element, {
              sitekey: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
              action: action,
            });
          } catch (error) {
            console.error("Error rendering reCAPTCHA:", error);
            setTimeout(() => {
              this.initializeRecaptcha();
            }, 1000);
          }
        });
      }
    }
  };

  onChange = (val, attr) => {
    this[attr] = val;
    this.errorMessage = "";

    if (attr === "repeatPassword" || attr === "password") {
      if (this.password && this.repeatPassword) {
        this.passwordsMatch = this.password === this.repeatPassword;
      } else {
        this.passwordsMatch = true;
      }
    }
  };

  onChangeAny = (val, attr) => {
    this[attr] = val;
    this.errorMessage = "";
  };

  onLogin = async (e) => {
    try {
      e.preventDefault();
      this.errorMessage = "";

      const token = document.querySelector(".g-recaptcha-response")?.value;

      if (!shouldSkipCaptcha() && !token) {
        this.errorMessage = "Please complete the security check";
        return;
      }

      const response = await this.props.store.api.post("/auth/login", {
        email: this.email,
        password: this.password,
        captchaToken: token,
      });

      const data = response.data;

      if (data?.redirectUrl) {
        window.location.href = data.redirectUrl;
      } else {
        if (data.token && data.profile) {
          this.props.store.loginWithDataTokenAndProfile(data);
        }

        if (data.profile.credits < 50) {
          toast(
            "To ensure uninterrupted usage of our services, please consider purchasing additional tokens from your profile as your current balance is running low."
          );
        }
      }
    } catch (error) {
      console.error("Login error:", error.response?.data);
      this.errorMessage = error.response?.data?.message || "Login failed";
    }
  };

  onSignup = async (e) => {
    try {
      e.preventDefault();
      this.errorMessage = "";

      if (!this.password.trim() || !this.repeatPassword.trim()) {
        this.passwordsMatch = true;
        return;
      }

      const token = document.querySelector(".g-recaptcha-response")?.value;

      if (!shouldSkipCaptcha() && !token) {
        this.errorMessage = "Please complete the security check";
        return;
      }

      const response = await this.props.store.api.post("/auth/signup", {
        referral: this.props.store.referral,
        plan: this.plan,
        fname: this.fname,
        lname: this.lname,
        email: this.email,
        password: this.password,
        phone: this.phone,
        captchaToken: token,
      });

      this.props.history.push("/login?signup=success");
    } catch (error) {
      if (error?.response?.data?.message) {
        this.errorMessage = error?.response?.data?.message;
      }
    }
  };

  render() {
    return (
      <>
        <Helmet>
          <title>{`NavixAI - Tools for Healthcare Professionals`}</title>
        </Helmet>
        <div className="w-full h-full min-h-screen relative">
          <video
            autoPlay
            loop
            muted
            playsInline
            className="absolute w-full h-full object-cover"
            style={{ zIndex: -1 }}
          >
            <source src={videoBg} type="video/webm" />
            Your browser does not support the video tag.
          </video>
          <div className="container mx-auto lg:px-4 py-4 min-h-screen flex flex-col md:items-center md:justify-center">
            <div className="text-center mb-6">
              <div className="w-52">
                <a href="https://navix.ai/">
                  <img src={Logo} />
                </a>
              </div>
            </div>
            <div
              className={`min-w-full mt-4 md:min-w-0 bg-white bg-opacity-50 rounded-xl shadow-xl backdrop-blur-xl transform font- transition-all w-1/2`}
            >
              <div className="align-bottom flex transform transition-all sm:align-middle">
                <NavLink
                  to="/signup"
                  className={`flex-1 justify-center transition py-4 px-4 pr-8 rounded-t-xl flex text-xl font-${
                    this.props.location.pathname === "/signup"
                      ? "semibold"
                      : "medium"
                  }
                  hover:bg-${
                    this.props.location.pathname === "/signup" ? "" : "gray-300"
                  } ${
                    this.props.location.pathname === "/signup"
                      ? "bg-gray-100 border-b-2 border-black"
                      : ""
                  } cursor-pointer`}
                >
                  Signup
                </NavLink>

                <NavLink
                  to="/login"
                  className={`flex-1 justify-center transition py-4 px-4 pr-8 rounded-t-xl flex text-xl font-${
                    this.props.location.pathname === "/login"
                      ? "semibold"
                      : "medium"
                  }
                  hover:bg-${
                    this.props.location.pathname === "/login" ? "" : "gray-300"
                  } ${
                    this.props.location.pathname === "/login"
                      ? "bg-gray-100 border-b-2 border-black"
                      : ""
                  } cursor-pointer`}
                >
                  Login
                </NavLink>
              </div>
              <div className="px-4 py-4 md:px-12 md:py-12">
                <Switch>
                  <Route path="/login">
                    <Logon
                      landingPageUrl={this.props.store.landingPageUrl}
                      email={this.email}
                      password={this.password}
                      signUp={this.signUpWithGoogle}
                      onChange={this.onChangeAny}
                      onLogin={this.onLogin}
                      isRecaptchaLoading={this.isRecaptchaLoading}
                    />
                  </Route>
                  <Route path="/signup">
                    <Signup
                      fname={this.fname}
                      lname={this.lname}
                      email={this.email}
                      password={this.password}
                      repeatPassword={this.repeatPassword}
                      phone={this.phone}
                      onChange={this.onChangeAny}
                      onSignup={this.onSignup}
                      isRecaptchaLoading={this.isRecaptchaLoading}
                    />
                  </Route>
                  <Route>
                    <Redirect to="/login" />
                  </Route>
                </Switch>
                {this.errorMessage ? (
                  <div className="text-red-600 bg-red-50 rounded-md p-1 text-center mt-4">
                    {this.errorMessage}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="flex justify-center absolute bottom-0 w-full text-center py-4bg-opacity-75">
            <a
              href={`https://www.navixhealth.com/navixai`}
              className="block text-gray-500 text-sm p-3 hover:bg-gray-200 cursor-pointer"
            >
              Visit our website for details
            </a>
            <a
              href={`https://www.navixhealth.com/terms-of-use`}
              className="block text-gray-500 text-sm p-3 hover:bg-gray-200 cursor-pointer"
            >
              Terms of Use
            </a>
          </div>
        </div>
      </>
    );
  }
}

const Logon = observer(
  ({
    active,
    email,
    password,
    onChange,
    onLogin,
    landingPageUrl,
    signUp,
    isRecaptchaLoading,
  }) => {
    useEffect(() => {
      const params = new URLSearchParams(window.location.search);
      if (params.get("signup") === "success") {
        toast.success("Signup successful! Please login to continue.", {
          duration: 5000,
          position: "top-center",
        });
        window.history.replaceState({}, "", "/login");
      }
    }, []);

    return (
      <>
        <form onSubmit={onLogin}>
          <div className="mt-3 text-center">
            <div className="text-3xl font-semibold text-gray-900">Log in</div>
            <p className="text-lg text-gray-500">Login to your account</p>
            <div className="flex flex-col flex-1">
              <label className="text-gray-900 text-sm block mt-4 inline-block text-left">
                Email Address
              </label>
              <input
                value={email}
                onChange={(e) => onChange(e.target.value, "email")}
                focus="true"
                type="email"
                className="rounded-md text-lg px-4 py-2 border border-white text-gray-700"
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.05)",
                }}
                placeholder="jean@smith.com"
              />
            </div>
            <div className="flex flex-col flex-1">
              <label className="text-gray-900 text-sm block mt-4 inline-block text-left">
                Password
              </label>
              <input
                value={password}
                onChange={(e) => onChange(e.target.value, "password")}
                type="password"
                className="rounded-md text-lg px-4 py-2 border border-white text-gray-700 inline-block"
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.05)",
                }}
                placeholder="*******"
              />
            </div>
            <div className="flex flex-col items-center justify-center mt-4">
              {isRecaptchaLoading && (
                <div className="text-gray-600 text-sm mb-4">
                  Loading security check...
                </div>
              )}
              <div
                id="recaptcha-container"
                className={`recaptcha-wrapper ${isRecaptchaLoading ? "hidden" : ""}`}
              ></div>
            </div>
            <div className="flex flex-col">
              <button
                type="submit"
                className="hover:bg-indigo-900 font-medium rounded-lg text-lg px-4 py-2 text-white mt-4 border border-indigo-600 inline-block"
                style={{ backgroundColor: "#4F46E5" }}
              >
                Log in
              </button>
              <NavLink
                to="/forgot-password"
                className="mt-4 text-gray-400 text-sm"
              >
                Forgot your password?
              </NavLink>
            </div>
          </div>
        </form>
      </>
    );
  }
);

const Signup = observer(
  ({
    active,
    fname,
    lname,
    email,
    password,
    repeatPassword,
    phone,
    onChange,
    onSignup,
    isRecaptchaLoading,
  }) => {
    const passwordsMatch =
      password && repeatPassword ? password === repeatPassword : true;

    useEffect(() => {
      onChange("pro", "plan");
    }, []);

    const handleSubmit = async (e) => {
      try {
        await onSignup(e);
        if (window.gtag) {
          window.gtag("event", "conversion", {
            send_to: "AW-11105204551/aGx1CM7e3OkYEMfyr68p",
          });
        }
      } catch (error) {
        console.error("Signup error:", error);
      }
    };

    return (
      <>
        <form onSubmit={handleSubmit}>
          <div className="mt-3 text-center">
            <div className="text-3xl font-semibold text-gray-900">
              Sign up for our Free Trial
            </div>
            <p className="text-md text-gray-500">Valid for seven (7) days</p>
            <p>
              <a
                href="https://www.navixhealth.com/navix-ai"
                className="text-blue-600"
              >
                Learn more about NavixAI on our website
              </a>
            </p>
            <div className="md:flex">
              <div className="flex flex-col min-w-0 md:pr-2 flex-1">
                <label className="text-gray-900 text-sm block mt-4 inline-block text-left">
                  First Name
                </label>
                <input
                  value={fname}
                  onChange={(e) => onChange(e.target.value, "fname")}
                  type="text"
                  className="rounded-md text-lg px-4 py-2 border border-white text-gray-700 inline-block w-auto"
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.05)",
                  }}
                  placeholder="Jean"
                />
              </div>
              <div className="flex flex-col min-w-0 md:pl-2 flex-1">
                <label className="text-gray-900 text-sm block mt-4 inline-block text-left">
                  Last Name
                </label>
                <input
                  value={lname}
                  onChange={(e) => onChange(e.target.value, "lname")}
                  type="text"
                  className="rounded-md text-lg px-4 py-2 border border-white text-gray-700 inline-block w-auto"
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.05)",
                  }}
                  placeholder="Smith"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-900 text-sm block mt-4 inline-block text-left">
                Email Address
              </label>
              <input
                value={email}
                onChange={(e) => onChange(e.target.value, "email")}
                focus="true"
                type="email"
                className="rounded-md text-lg px-4 py-2 border border-white text-gray-700"
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.05)",
                }}
                placeholder="jean@smith.com"
              />
            </div>
            <div className="flex flex-col">
              <label className="text-gray-900 text-sm block mt-4 inline-block text-left">
                Phone Number (Optional)
              </label>
              <PhoneInput
                country={"us"}
                value={phone}
                onChange={(value) => onChange(value, "phone")}
                inputClass="!w-full rounded-md text-lg px-4 py-2 border border-white text-gray-700"
                containerClass="!w-full"
                inputStyle={{
                  backgroundColor: "rgba(255, 255, 255, 0.05)",
                  width: "100%",
                  height: "44px",
                }}
                buttonStyle={{
                  backgroundColor: "rgba(255, 255, 255, 0.05)",
                  border: "1px solid white",
                  borderRight: "none",
                }}
              />
            </div>
            <div className="flex flex-col">
              <label className="text-gray-900 text-sm block mt-4 inline-block text-left">
                Password
              </label>
              <input
                value={password}
                onChange={(e) => onChange(e.target.value, "password")}
                type="password"
                className="rounded-md text-lg px-4 py-2 border border-white text-gray-700 inline-block"
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.05)",
                }}
                placeholder="*******"
              />
            </div>
            <div className="flex flex-col">
              <label className="text-gray-900 text-sm block mt-4 inline-block text-left">
                Repeat Password
              </label>
              <input
                value={repeatPassword}
                onChange={(e) => onChange(e.target.value, "repeatPassword")}
                type="password"
                className="rounded-md text-lg px-4 py-2 border border-white text-gray-700 inline-block"
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.05)",
                }}
                placeholder="*******"
              />
              {password !== repeatPassword && (
                <p className="text-red-500 text-sm">Passwords don't match</p>
              )}
            </div>
            <div className="flex flex-col items-center justify-center mt-4">
              {isRecaptchaLoading && (
                <div className="text-gray-600 text-sm mb-4">
                  Loading security check...
                </div>
              )}
              <div
                id="recaptcha-container"
                className={`recaptcha-wrapper ${isRecaptchaLoading ? "hidden" : ""}`}
              ></div>
            </div>
            <div className="flex flex-col">
              <button
                type="submit"
                disabled={!passwordsMatch}
                className="hover:bg-indigo-600 bg-indigo-500 font-medium rounded-lg text-lg px-4 py-2 text-white mt-4 border border-indigo-600 inline-block"
                style={{ backgroundColor: "#4F46E5" }}
              >
                Submit
              </button>

              <div className="text-sm mt-4 text-gray-500">
                By clicking "Submit", you agree to our{" "}
                <a href={`https://www.navixhealth.com/navixai-terms-of-use`}>
                  Terms
                </a>{" "}
                and confirm you're 18 years or older.
              </div>
            </div>
          </div>
        </form>
      </>
    );
  }
);

export default withRouter(Login);
