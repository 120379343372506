import React, { useState } from "react";
import { observer } from "mobx-react";
import { vobStore } from "../VOBStore";
import { VOB, Dependent } from "../models/vob.types";
import OutlineButton from "./OutlineButton";
import { ChartBarIcon } from "@heroicons/react/outline";

const LevelOfCareCard = ({ title, percentage, lengthOfStay }) => (
  <div className="border border-indigo-100 rounded-lg p-6 bg-white">
    <div className="flex items-center gap-2 mb-4">
      <ChartBarIcon className="w-5 h-5 text-indigo-600" />
      <span className="text-lg font-medium">{title}</span>
    </div>
    <div className="grid grid-cols-2 gap-4">
      <div>
        <div className="text-2xl font-semibold text-indigo-600">
          ${percentage}
        </div>
        <div className="text-sm text-gray-600">
          Average Percentage of Payment
        </div>
      </div>
      <div>
        <div className="text-2xl font-semibold text-indigo-600">
          {lengthOfStay}
        </div>
        <div className="text-sm text-gray-600">Average Length of Stay</div>
      </div>
    </div>
  </div>
);

const EstimatesVOB = observer(() => {
  const [formData, setFormData] = useState(new VOB());
  const [dependents, setDependents] = useState([new Dependent()]);
  const { loading, currentBenefits } = vobStore;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleDependentChange = (index, field, value) => {
    const newDependents = [...dependents];
    newDependents[index] = {
      ...newDependents[index],
      [field]: value,
    };
    setDependents(newDependents);
  };

  const addDependent = () => {
    setDependents([...dependents, new Dependent()]);
  };

  const handleSubmit = async () => {
    const data = {
      ...formData,
      dependents: dependents.filter((d) => d.firstName || d.lastName),
    };
    await vobStore.getEstimates(data);
  };

  return (
    <div className="p-6">
      <div className="max-w-5xl mx-auto">
        <div className="bg-white shadow rounded-lg">
          <div className="p-6 space-y-6">
            {/* Form Content */}
            <div className="grid grid-cols-2 gap-6">
              {/* Business Information */}
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Business*
                </label>
                <input
                  type="text"
                  name="business"
                  value={formData.business}
                  onChange={handleInputChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  First Name*
                </label>
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Last Name*
                </label>
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Date of Birth*
                </label>
                <input
                  type="date"
                  name="dateOfBirth"
                  value={formData.dateOfBirth}
                  onChange={handleInputChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Member ID*
                </label>
                <input
                  type="text"
                  name="memberId"
                  value={formData.memberId}
                  onChange={handleInputChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div className="grid grid-cols-4 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  LOC
                </label>
                <select
                  name="loc"
                  value={formData.loc}
                  onChange={handleInputChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                >
                  <option value="">Select LOC</option>
                  <option value="residential">Residential</option>
                  <option value="php">PHP</option>
                  <option value="iop">IOP</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Payer Name
                </label>
                <select
                  name="payerName"
                  value={formData.payerName}
                  onChange={handleInputChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                >
                  <option value="">Select Payer</option>
                  <option value="bcbs">Blue Cross Blue Shield</option>
                  <option value="aetna">Aetna</option>
                  <option value="cigna">Cigna</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Employer Name
                </label>
                <input
                  type="text"
                  name="employerName"
                  value={formData.employerName}
                  onChange={handleInputChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Prefix
                </label>
                <input
                  type="text"
                  name="prefix"
                  value={formData.prefix}
                  onChange={handleInputChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <OutlineButton
                onClick={handleSubmit}
                loading={loading}
                disabled={loading}
              >
                Submit
              </OutlineButton>
            </div>

            {/* Level of Care Section */}
            {currentBenefits?.levelOfCare && (
              <div className="space-y-6">
                <h2 className="text-lg font-medium text-gray-900">
                  Level of Care
                </h2>
                <div className="grid grid-cols-3 gap-6">
                  {Object.entries(currentBenefits.levelOfCare).map(
                    ([key, data]) => (
                      <LevelOfCareCard
                        key={key}
                        title={data.name}
                        percentage={data.averagePaymentPercentage}
                        lengthOfStay={data.averageLengthOfStay}
                      />
                    )
                  )}
                </div>

                {/* Confidence Estimate */}
                <div className="mt-6 bg-white rounded-lg border border-gray-200 p-4">
                  <div className="flex justify-between items-center">
                    <span className="text-sm font-medium text-gray-700">
                      Confidence Estimate
                    </span>
                    <span className="text-sm font-medium text-orange-500">
                      {currentBenefits.confidenceEstimate}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

export default EstimatesVOB;
