import { makeAutoObservable, runInAction } from "mobx";
import { VOB, Benefits } from "./models/vob.types";
import { vobService } from "./services/vob.service";

class VOBStore {
  vobs = [];
  loading = false;
  error = null;
  currentBenefits = null;
  pagination = {
    currentPage: 1,
    totalPages: 1,
    totalItems: 0,
    itemsPerPage: 25,
  };
  sortConfig = {
    field: "dateCreated",
    direction: "desc",
  };

  constructor() {
    makeAutoObservable(this);
  }

  setLoading(status) {
    this.loading = status;
  }

  setError(error) {
    this.error = error;
  }

  setPagination(pagination) {
    this.pagination = { ...this.pagination, ...pagination };
  }

  setSortConfig(config) {
    this.sortConfig = { ...this.sortConfig, ...config };
  }

  setCurrentBenefits(benefits) {
    this.currentBenefits = benefits;
  }

  async fetchVOBs() {
    try {
      this.setLoading(true);
      this.setError(null);

      // TODO: Replace with actual API call
      // For now using mock data
      const mockData = {
        data: [
          {
            id: "1",
            patientName: "Leslie Alexander",
            coverageStatus: "Active",
            payerName: "Primary",
            instant: true,
            dateCreated: "2024-07-24",
          },
          {
            id: "2",
            patientName: "Esther Howard",
            coverageStatus: "Active",
            payerName: "Secondary",
            instant: true,
            dateCreated: "2023-08-31",
          },
          // Add more mock data here
        ],
        pagination: {
          currentPage: 1,
          totalPages: 5,
          totalItems: 125,
          itemsPerPage: 25,
        },
      };

      // Simulate API call
      await new Promise((resolve) => setTimeout(resolve, 1000));

      runInAction(() => {
        this.vobs = mockData.data;
        this.setPagination(mockData.pagination);
      });
    } catch (error) {
      this.setError(error.message);
    } finally {
      this.setLoading(false);
    }
  }

  async archiveVOB(id) {
    try {
      this.setError(null);
      await vobService.archiveVOB(id);
      runInAction(() => {
        this.vobs = this.vobs.filter((vob) => vob.id !== id);
      });
    } catch (error) {
      this.setError(error.message);
    }
  }

  async getBenefits(data) {
    try {
      this.setLoading(true);
      this.setError(null);

      const response = await vobService.getBenefits(data);

      runInAction(() => {
        // Create a new Benefits instance with the response data
        const benefits = new Benefits({
          provider: response.provider,
          subscriber: response.subscriber,
          providerInformation: response.providerInformation,
          payerInformation: response.payerInformation,
          planInformation: response.planInformation,
          coverageDetails: response.coverageDetails,
          errors: response.errors,
          meta: response.meta,
          lastFetched: response.lastFetched,
          raw: response.raw, // Keep the raw data
        });

        this.currentBenefits = benefits;
      });

      return this.currentBenefits;
    } catch (error) {
      let errorMessage = "Failed to retrieve benefits";

      // Handle Optum specific error codes
      if (error.response?.data?.errors) {
        errorMessage = error.response.data.errors
          .map((err) => err.message)
          .join(". ");
      }

      this.setError(errorMessage);
      throw error;
    } finally {
      this.setLoading(false);
    }
  }

  async getEstimates(data) {
    try {
      this.setLoading(true);
      this.setError(null);
      this.setCurrentBenefits(null);

      // Mock data for estimates
      const mockResponse = {
        provider: {
          organizationName: "Navix Health California Branch",
          npi: "1123123232",
        },
        subscriber: {
          memberId: data.memberId || "1123213213",
          firstName: data.firstName || "John",
          lastName: data.lastName || "Mey",
          dob: data.dateOfBirth || "01-20-2002",
        },
        levelOfCare: {
          stabilization: {
            name: "Stabilization",
            averagePaymentPercentage: 30,
            averageLengthOfStay: 10,
          },
          residential: {
            name: "Residential",
            averagePaymentPercentage: 40,
            averageLengthOfStay: 20,
          },
          php: {
            name: "PHP",
            averagePaymentPercentage: 30,
            averageLengthOfStay: 40,
          },
          iop: {
            name: "IOP",
            averagePaymentPercentage: 50,
            averageLengthOfStay: 30,
          },
          op: {
            name: "OP",
            averagePaymentPercentage: 40,
            averageLengthOfStay: 10,
          },
          professionalServices: {
            name: "Professional Services",
            averagePaymentPercentage: 80,
            averageLengthOfStay: 20,
          },
        },
        confidenceEstimate: "Medium",
      };

      // Simulate API call
      await new Promise((resolve) => setTimeout(resolve, 2000));

      runInAction(() => {
        this.currentBenefits = mockResponse;
      });

      return this.currentBenefits;
    } catch (error) {
      this.setError(error.message);
      throw error;
    } finally {
      this.setLoading(false);
    }
  }

  async createVOB(data, type = "instant") {
    try {
      this.setLoading(true);
      this.setError(null);

      if (type === "instant") {
        await vobService.createInstantVOB(data);
      } else if (type === "estimates") {
        await vobService.createEstimatesVOB(data);
      }

      // Refresh the VOB list
      await this.fetchVOBs();
    } catch (error) {
      this.setError(error.message);
      throw error;
    } finally {
      this.setLoading(false);
    }
  }
}

export const vobStore = new VOBStore();
