import { makeObservable, observable, action, computed } from "mobx";
import { EventEmitter } from "events";
import {
  updateHistory,
  fetchThreadHistories,
  waitForTranscription,
  getHistory,
} from "./api.service.v2";
import {
  runAssistant,
  createThread,
  addMessage,
  fetchMessages,
} from "./azure-api-service";
import { deleteHistory } from "../../components/History";
import { PERSONAS } from "./Personas";
import { toast } from "react-hot-toast";

class NavixScribeV2Store extends EventEmitter {
  constructor(rootStore = null) {
    super();
    this.rootStore = rootStore;

    this.tool = {};
    this.activeSection = "input";
    this.activeRecord = "";
    this.outputActiveRecord = "";
    this.triggerSection = "";
    this.selectedPersona = null;
    this.selectedPronouns = null;
    this.selectedNoteStyle = null;
    this.selectedPatientName = null;

    this.isRecording = false;
    this.recordingStartTime = 0;
    this.elapsedTime = 0;
    this.savedDuration = 0;

    this.threads = [];
    this.selectedThread = null;
    this.messages = [];
    this.loading = false;
    this.lastMessageTimestamp = null;
    this.isAwaitingResponse = false;

    this.awsUrl = null;
    this.audioDuration = 0;

    this.activeTranscriptionId = null;
    this.isTranscriptionInProgress = false;

    this.output = "";
    this.utterances = "";
    this.outputType = "";
    this.inputActiveRecord = "";
    this.inputDocument = null;
    this.inputForm = {
      title: "",
      inputText: "",
      file: "",
      filename: "",
      audioDuration: 0,
      audioDurationString: "",
      output: "",
    };
    this.inputUploads = [];
    this.inputHistories = [];
    this.clinicalNotesHistories = [];
    this.questionHistories = [];
    this.summaryHistories = [];
    this.showInputAccordion = false;

    this.isNewThread = false;

    this.selectedClient = null;

    this.transcriptionShouldCreateNewThread = false;

    // Initialize default settings if not present
    if (!localStorage.getItem("selectedPersona")) {
      this.setSelectedPersona({ persona: "Therapist", customResponse: "" });
    }
    if (!localStorage.getItem("selectedPronouns")) {
      this.setPronouns("He/She");
    }
    if (!localStorage.getItem("selectedPatientName")) {
      this.setPatientName("Client");
    }

    // Load settings from localStorage
    this.loadSelectedPersona();
    this.loadPronouns();
    this.loadNoteStyle();
    this.loadPatientName();

    // Make observables, actions, and computed properties
    makeObservable(this, {
      // Observables
      tool: observable,
      activeSection: observable,
      activeRecord: observable,
      outputActiveRecord: observable,
      triggerSection: observable,
      selectedPersona: observable,
      isRecording: observable,
      recordingStartTime: observable,
      elapsedTime: observable,
      savedDuration: observable,
      threads: observable,
      selectedThread: observable,
      messages: observable,
      loading: observable,
      lastMessageTimestamp: observable,
      isAwaitingResponse: observable,
      output: observable,
      utterances: observable,
      outputType: observable,
      inputActiveRecord: observable,
      inputDocument: observable,
      inputForm: observable,
      inputUploads: observable,
      inputHistories: observable,
      clinicalNotesHistories: observable,
      questionHistories: observable,
      summaryHistories: observable,
      selectedPronouns: observable,
      selectedNoteStyle: observable,
      selectedPatientName: observable,
      awsUrl: observable,
      audioDuration: observable,
      activeTranscriptionId: observable,
      isTranscriptionInProgress: observable,
      isNewThread: observable,
      selectedClient: observable,
      transcriptionShouldCreateNewThread: observable,

      // Actions
      startRecording: action,
      stopRecording: action,
      updateElapsedTime: action,
      setSelectedPersona: action,
      loadSelectedPersona: action,
      setPronouns: action,
      setNoteStyle: action,
      setPatientName: action,
      clearSetting: action,
      setTool: action,
      setInputActiveRecord: action,
      setInputDocument: action,
      setActiveHistory: action,
      setInputHistories: action,
      setClinicalNotesHistories: action,
      setQuestionHistories: action,
      setSummaryHistories: action,
      setInputUploads: action,
      setOutput: action,
      setInputForm: action,
      setOutputActiveRecord: action,
      setShowInputAccordion: action,
      handleSaveFeedbackValue: action,
      setTriggerSection: action,
      clear: action,
      fetchThreads: action,
      selectThread: action,
      fetchThreadMessages: action,
      setMessages: action,
      setSelectedThread: action,
      setLoading: action,
      setIsAwaitingResponse: action,
      sendMessage: action,
      createNewThread: action,
      handleDelete: action,
      loadPronouns: action,
      loadNoteStyle: action,
      loadPatientName: action,
      setAwsUrl: action,
      setAudioDuration: action,
      failedChunks: observable,
      addFailedChunk: action,
      clearFailedChunks: action,
      currentRecordingId: observable,
      recordingStatus: observable,
      setCurrentRecordingId: action,
      setRecordingStatus: action,
      resetRecording: action,
      startNewRecording: action,
      canResumeRecording: computed,
      startTranscriptionTracking: action,
      pollTranscriptionStatus: action,
      stopTranscriptionTracking: action,
      setIsNewThread: action,
      setSelectedClient: action,
      setTranscriptionShouldCreateNewThread: action,

      personaPrompt: computed,

      resetState: action,

      emitScrollToBottom: action,

      emitProcessingHistoryCreated: action,
    });

    // Set up socket listeners
    if (rootStore?.store?.socket) {
      rootStore.store.socket.on(
        "processing-history-created",
        ({ processingHistoryId }) => {
          this.emitProcessingHistoryCreated(processingHistoryId);
        }
      );
    }

    // Bind methods if necessary (optional, depending on usage)
    // this.sendMessage = this.sendMessage.bind(this);
    // ... bind other methods as needed
  }

  resetState = () => {
    this.selectedThread = null;
    this.messages = [];
    this.output = "";
    this.utterances = "";
    this.outputType = "";
    this.inputActiveRecord = "";
    this.inputDocument = null;
    this.awsUrl = null;
    this.audioDuration = 0;
    this.isAwaitingResponse = false;
    this.recordingStatus = "idle";
    this.currentRecordingId = null;
    this.setIsNewThread(true);
    this.emit("stateReset");
  };

  // -------------------------
  // Recording Methods
  // -------------------------

  startRecording = () => {
    this.isRecording = true;
    this.recordingStartTime = Date.now();
    this.updateElapsedTime();
  };

  stopRecording = () => {
    this.isRecording = false;
    this.savedDuration = this.elapsedTime;
    this.recordingStartTime = 0;
    this.elapsedTime = 0;
  };

  updateElapsedTime = () => {
    if (this.isRecording) {
      this.elapsedTime = Date.now() - this.recordingStartTime;
      setTimeout(this.updateElapsedTime, 1000);
    }
  };

  // -------------------------
  // Persona Methods
  // -------------------------

  setSelectedPersona = (val) => {
    if (val.persona !== "Custom") {
      const selectedPersonaObj = this.PERSONAS.find(
        (p) => p.name === val.persona
      );
      if (selectedPersonaObj) {
        val.customResponse = selectedPersonaObj.description;
      }
    }
    this.selectedPersona = val;
    localStorage.setItem("selectedPersona", JSON.stringify(val));
  };

  loadSelectedPersona = () => {
    const savedPersona = localStorage.getItem("selectedPersona");
    if (savedPersona) {
      this.selectedPersona = JSON.parse(savedPersona);
    }
  };

  // -------------------------
  // Pronouns Methods
  // -------------------------

  setPronouns = (val) => {
    this.selectedPronouns = val;
    if (val === "") {
      localStorage.removeItem("selectedPronouns");
    } else {
      localStorage.setItem("selectedPronouns", val);
    }
  };

  getPronouns = () => {
    return localStorage.getItem("selectedPronouns") || "";
  };

  loadPronouns = () => {
    const savedPronouns = localStorage.getItem("selectedPronouns");
    if (savedPronouns) {
      this.selectedPronouns = savedPronouns;
    }
  };

  // -------------------------
  // Note Style Methods
  // -------------------------

  setNoteStyle = (val) => {
    this.selectedNoteStyle = val;
    if (val === "") {
      localStorage.removeItem("selectedNoteStyle");
    } else {
      localStorage.setItem("selectedNoteStyle", val);
    }
  };

  getNoteStyle = () => {
    return localStorage.getItem("selectedNoteStyle") || "";
  };

  loadNoteStyle = () => {
    const savedNoteStyle = localStorage.getItem("selectedNoteStyle");
    if (savedNoteStyle) {
      this.selectedNoteStyle = savedNoteStyle;
    }
  };

  // -------------------------
  // Patient Name Methods
  // -------------------------

  setPatientName = (val) => {
    this.selectedPatientName = val;
    if (val === "") {
      localStorage.removeItem("selectedPatientName");
    } else {
      localStorage.setItem("selectedPatientName", val);
    }
  };

  getPatientName = () => {
    return localStorage.getItem("selectedPatientName") || "";
  };

  loadPatientName = () => {
    const savedPatientName = localStorage.getItem("selectedPatientName");
    if (savedPatientName) {
      this.selectedPatientName = savedPatientName;
    }
  };

  // -------------------------
  // Clear Settings
  // -------------------------

  clearSetting = (key) => {
    localStorage.removeItem(key);
    if (key === "selectedPersona") {
      this.selectedPersona = null;
    } else if (key === "selectedPronouns") {
      this.selectedPronouns = null;
    } else if (key === "selectedNoteStyle") {
      this.selectedNoteStyle = null;
    } else if (key === "selectedPatientName") {
      this.selectedPatientName = null;
    }
  };

  // -------------------------
  // Tool Methods
  // -------------------------

  setTool = (tool) => {
    this.tool = tool;
  };

  output = "";
  utterances = "";
  outputType = "";
  inputActiveRecord = "";
  inputDocument = null;
  inputForm = {
    title: "",
    inputText: "",
    file: "",
    filename: "",
    audioDuration: 0,
    audioDurationString: "",
    output: "",
  };
  inputUploads = [];
  inputHistories = [];
  clinicalNotesHistories = [];
  questionHistories = [];
  summaryHistories = [];
  failedChunks = [];
  currentRecordingId = null;
  recordingStatus = "idle"; // 'idle' | 'recording' | 'paused' | 'completed'
  get inputOutput() {
    return this.inputForm.output;
  }
  // -------------------------
  // Input Methods
  // -------------------------

  setInputActiveRecord = (val) => {
    console.log("setInputActiveRecord - val:", val);
    if (val.output === null) {
      console.error("setInputActiveRecord - val.output is null", val);
    }
    this.inputActiveRecord = val;
    console.log(
      "setInputActiveRecord - this.inputActiveRecord:",
      this.inputActiveRecord
    );
  };

  setInputDocument = (val) => {
    this.inputDocument = val;
  };

  setActiveHistory = (historyId, section) => {
    this.activeRecord = historyId;
    this.activeSection = section;
    console.log(
      "setActiveHistory - History ID:",
      historyId,
      "Section:",
      section
    );
  };

  // -------------------------
  // Histories Methods
  // -------------------------

  setInputHistories = (val) => {
    this.inputHistories = val;
  };

  setClinicalNotesHistories = (val) => {
    this.clinicalNotesHistories = val;
  };

  setQuestionHistories = (val) => {
    this.questionHistories = val;
  };

  setSummaryHistories = (val) => {
    this.summaryHistories = val;
  };

  // -------------------------
  // Uploads Methods
  // -------------------------

  setInputUploads = (val) => {
    this.inputUploads = val;
  };

  // -------------------------
  // Output Methods
  // -------------------------

  setOutput = (val, utterances, type) => {
    this.output = val;
    this.utterances = utterances;
    this.outputType = type;
  };

  setInputForm = (name, value) => {
    this.inputForm[name] = value;
  };

  setOutputActiveRecord = (history) => {
    this.outputActiveRecord = history;
  };

  setShowInputAccordion = (val) => {
    this.showInputAccordion = val;
  };

  // -------------------------
  // Feedback Handling
  // -------------------------

  handleSaveFeedbackValue = async (value, id) => {
    try {
      await updateHistory(id, { feedbackValue: value });

      if (this.outputActiveRecord?._id === id) {
        this.outputActiveRecord.feedbackValue = value;
      }

      return {
        success: true,
        message: "Successfully submitted your feedback.",
      };
    } catch (error) {
      console.error("Error in handleSaveFeedbackValue:", error);
      return {
        success: false,
        message: "Something went wrong!",
      };
    }
  };

  // -------------------------
  // Trigger Section
  // -------------------------

  setTriggerSection = (val) => {
    this.triggerSection = val;
  };

  // -------------------------
  // Clear Tool
  // -------------------------

  clear = () => this.setTool({});

  // -------------------------
  // Thread Methods
  // -------------------------

  fetchThreads = async () => {
    try {
      const histories = await fetchThreadHistories();
      this.threads = histories.result.map((history) => ({
        id: history.threadId,
        title: history.title,
      }));
    } catch (error) {
      console.error("Failed to fetch threads:", error);
      this.threads = [];
    }
  };

  selectThread = async (threadId) => {
    if (this.selectedThread !== threadId) {
      this.setLoading(true);
      this.selectedThread = threadId;
      await this.fetchThreadMessages(threadId);
      this.setLoading(false);
    }
    this.emitScrollToBottom();
  };

  fetchThreadMessages = async (threadId) => {
    try {
      const fetchedMessages = await fetchMessages(threadId);
      this.setMessages(fetchedMessages);
      if (fetchedMessages.length > 0) {
        const lastTimestamp =
          fetchedMessages[fetchedMessages.length - 1].created_at;
        this.lastMessageTimestamp = lastTimestamp;
      }
    } catch (error) {
      console.error("Failed to fetch messages:", error);
      this.setMessages([]);
    }
  };

  setMessages = (messages) => {
    if (messages.length > this.messages.length) {
      this.setIsNewThread(true);
    }

    this.messages = messages;
    this.emit("messagesUpdated");
  };

  setSelectedThread = (threadId) => {
    if (this.isRecording === true) {
      console.log("Thread selection prevented during recording");
      return;
    }

    if (this.selectedThread !== threadId) {
      this.setLoading(true);
      this.selectedThread = threadId;
      this.fetchThreadMessages(threadId);
      this.emit("threadAdded");
      this.setLoading(false);
    }
    this.emitScrollToBottom();
  };

  setLoading = (value) => {
    this.loading = value;
  };

  setIsAwaitingResponse = (value) => {
    this.isAwaitingResponse = value;
  };

  // -------------------------
  // Messaging Methods
  // -------------------------

  sendMessage = async (message) => {
    if (this.selectedThread) {
      this.setIsAwaitingResponse(true);
      try {
        await addMessage(this.selectedThread, "user", message);
        const response = await runAssistant(
          message,
          this.selectedThread,
          "asst_HagPjnY7H8EYT5gRm8o8DSsX",
          ""
        );

        this.setIsNewThread(true);

        const newMessages = [
          ...this.messages,
          { role: "user", content: message },
          { role: "assistant", content: response.content },
        ];
        this.setMessages(newMessages);
      } catch (error) {
        console.error("Error in sendMessage:", error);
      } finally {
        this.setIsAwaitingResponse(false);
      }
    }
  };

  createNewThread = async () => {
    try {
      this.resetThreadState();
      const threadResponse = await createThread();
      const threadId = threadResponse.thread_id;
      this.threads.push({ id: threadId, title: "New Conversation" });
      this.setIsNewThread(true);
      await this.selectThread(threadId);
    } catch (error) {
      console.error("Error in createNewThread:", error);
    }
  };

  handleDelete = async (id) => {
    try {
      const success = await deleteHistory(this.rootStore?.store, id);
      if (success) {
        console.log("History deleted successfully");
        this.threads = this.threads.filter((thread) => thread.id !== id);
        if (this.selectedThread === id) {
          this.selectedThread = null;
        }
      } else {
        console.error("Failed to delete history");
      }
    } catch (error) {
      console.error("Error in handleDelete:", error);
    }
  };

  // -------------------------
  // Personas
  // -------------------------

  PERSONAS = PERSONAS;

  // -------------------------
  // Computed Properties
  // -------------------------

  get personaPrompt() {
    if (!this.selectedPersona) return "";

    if (this.selectedPersona.persona === "Custom") {
      return this.selectedPersona.customResponse;
    }

    const selectedPersonaObj = this.PERSONAS.find(
      (p) => p.name === this.selectedPersona.persona
    );
    return selectedPersonaObj ? selectedPersonaObj.prompt : "";
  }

  get canResumeRecording() {
    return this.currentRecordingId && this.recordingStatus === "paused";
  }

  loadPronouns = () => {
    const savedPronouns = localStorage.getItem("selectedPronouns");
    if (savedPronouns) {
      this.selectedPronouns = savedPronouns;
    }
  };
  // -------------------------
  // Transcription Tracking
  // -------------------------

  startTranscriptionTracking = (
    transcriptionId,
    shouldCreateNewThread = false
  ) => {
    this.activeTranscriptionId = transcriptionId;
    this.isTranscriptionInProgress = true;
    this.transcriptionShouldCreateNewThread = shouldCreateNewThread;
    this.pollTranscriptionStatus(transcriptionId);
  };

  pollTranscriptionStatus = async (transcriptionId) => {
    if (!this.isTranscriptionInProgress) return;

    try {
      const completedHistory = await waitForTranscription(transcriptionId);

      if (completedHistory) {
        if (completedHistory.threadStatus !== "completed") {
          let threadId = this.transcriptionShouldCreateNewThread
            ? null
            : this.selectedThread;

          if (!threadId) {
            const processedKey = `processed_${transcriptionId}`;
            if (sessionStorage.getItem(processedKey)) {
              console.log("Thread creation already processed, skipping...");
              return;
            }
            sessionStorage.setItem(processedKey, "true");

            if (completedHistory.threadId) {
              threadId = completedHistory.threadId;
              console.log("Using existing thread from history:", threadId);
            } else {
              console.log("No thread found in history, creating new thread");
              await updateHistory(transcriptionId, {
                makeAwsUrlAvailable: true,
              });

              const newThread = await createThread();
              threadId = newThread.id;

              await updateHistory(transcriptionId, {
                threadId: threadId,
              });
            }

            this.setSelectedThread(threadId);
          }

          const transcriptionText = completedHistory.outputs.join(" ");
          if (transcriptionText.trim() !== "") {
            await addMessage(threadId, "user", transcriptionText, false);
            await this.fetchThreadMessages(threadId);
          }
        }

        this.isTranscriptionInProgress = false;
        this.activeTranscriptionId = null;

        // Emit both events to ensure UI updates
        this.emit("historyUpdated");
        this.emit("transcriptionCompleted");

        toast.success("Transcription completed.", {
          duration: 5000,
        });
      }
    } catch (error) {
      console.error("Error in pollTranscriptionStatus:", error);
      this.isTranscriptionInProgress = false;
      this.activeTranscriptionId = null;
      this.transcriptionShouldCreateNewThread = false;

      // Emit error event
      this.emit("transcriptionError", error);
      toast.error("Error during transcription processing");
    }
  };

  setTranscriptionCompleted = (threadId) => {
    this.isTranscriptionInProgress = false;
    this.activeTranscriptionId = null;
    this.transcriptionShouldCreateNewThread = false;
    this.recordingStatus = "idle";

    this.emit("historyUpdated");
    this.emit("transcriptionCompleted");

    this.setSelectedThread(threadId);
  };

  stopTranscriptionTracking = () => {
    this.isTranscriptionInProgress = false;
    this.activeTranscriptionId = null;
  };

  getHistory = async (id) => {
    const history = await getHistory(id);
    return history;
  };

  setAwsUrl = (url) => {
    this.awsUrl = url;
  };

  /**
   * Sets the audio duration.
   * @param {number} duration - The duration of the audio in milliseconds.
   */
  setAudioDuration = (duration) => {
    console.log("Setting audio duration:", duration);
    this.audioDuration = duration;
  };

  addFailedChunk = (chunk) => {
    this.failedChunks.push(chunk);
  };

  clearFailedChunks = () => {
    this.failedChunks = [];
  };

  setCurrentRecordingId = (id) => {
    console.log("Setting current recording ID:", id); // Debug log
    this.currentRecordingId = id;
    // // Optionally persist to localStorage
    // if (id) {
    //   localStorage.setItem("currentRecordingId", id);
    // } else {
    //   localStorage.removeItem("currentRecordingId");
    // }
  };

  resetRecording() {
    this.currentRecordingId = null;
    this.recordingStatus = "idle";
  }

  setRecordingStatus = (status) => {
    this.recordingStatus = status;
  };

  startNewRecording(id) {
    this.currentRecordingId = id;
    this.recordingStatus = "recording";
  }

  pauseRecording = () => {
    this.isRecording = false;
    this.recordingStatus = "paused";
    this.savedDuration = this.elapsedTime;
  };

  setIsNewThread = (value) => {
    this.isNewThread = value;
  };

  emitScrollToBottom = () => {
    this.emit("scrollToBottom");
  };

  resetThreadState = () => {
    this.setIsNewThread(false);
    this.messages = [];
  };

  setSelectedClient = (client) => {
    console.log("Setting selected client:", client);
    this.selectedClient = client;
    this.emit("clientSelected", client);
  };

  getSelectedClient = () => {
    return this.selectedClient;
  };

  emitProcessingHistoryCreated = (processingHistoryId) => {
    console.log("Processing history created with ID:", processingHistoryId);
    this.emit("processingHistoryCreated", processingHistoryId);
  };

  setTranscriptionShouldCreateNewThread = (value) => {
    this.transcriptionShouldCreateNewThread = value;
  };
}

export const navixScribeV2Store = new NavixScribeV2Store();
