import React, { useState, useEffect } from "react";
import { observer, inject } from "mobx-react";
import { vobStore } from "../VOBStore";
import { VOB, Dependent } from "../models/vob.types";
import OutlineButton from "./OutlineButton";
import toast from "react-hot-toast";

const InstantVOB = inject("store")(
  observer(({ store }) => {
    const [formData, setFormData] = useState(new VOB());
    const [dependents, setDependents] = useState([new Dependent()]);
    const { loading, currentBenefits, error } = vobStore;

    useEffect(() => {
      if (store.profile) {
        setFormData((prev) => ({
          ...prev,
          location: store.profile.vobLocation || "",
          npi: store.profile.vobNpi || "",
        }));
      }
    }, [store.profile]);

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleDependentChange = (index, field, value) => {
      const newDependents = [...dependents];
      newDependents[index] = {
        ...newDependents[index],
        [field]: value,
      };
      setDependents(newDependents);
    };

    const addDependent = () => {
      setDependents([...dependents, new Dependent()]);
    };

    const validateForm = () => {
      const requiredFields = [
        "firstName",
        "lastName",
        "memberId",
        "dateOfBirth",
      ];
      const missingFields = requiredFields.filter((field) => !formData[field]);

      if (missingFields.length > 0) {
        toast.error(
          `Please fill in required fields: ${missingFields.join(", ")}`
        );
        return false;
      }
      return true;
    };

    const handleGetBenefits = async () => {
      if (!validateForm()) return;

      try {
        const data = {
          ...formData,
          dependents: dependents.filter((d) => d.firstName && d.lastName),
        };
        await vobStore.getBenefits(data);
        toast.success("Benefits retrieved successfully");
      } catch (error) {
        toast.error(error.message || "Failed to retrieve benefits");
      }
    };

    return (
      <div className="p-6">
        <div className="max-w-5xl mx-auto">
          <div className="bg-white shadow rounded-lg">
            <div className="p-6 space-y-6">
              {/* Form Content */}
              <div className="grid grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Location
                  </label>
                  <input
                    type="text"
                    name="location"
                    value={formData.location}
                    onChange={handleInputChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    placeholder="Enter location"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    NPI
                  </label>
                  <input
                    type="text"
                    name="npi"
                    value={formData.npi}
                    onChange={handleInputChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    placeholder="Enter NPI"
                  />
                </div>
              </div>

              <div className="grid grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    First Name *
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    required
                    value={formData.firstName}
                    onChange={handleInputChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Last Name *
                  </label>
                  <input
                    type="text"
                    name="lastName"
                    required
                    value={formData.lastName}
                    onChange={handleInputChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div className="grid grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Member ID *
                  </label>
                  <input
                    type="text"
                    name="memberId"
                    required
                    value={formData.memberId}
                    onChange={handleInputChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Date of Birth *
                  </label>
                  <input
                    type="date"
                    name="dateOfBirth"
                    required
                    value={formData.dateOfBirth}
                    onChange={handleInputChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div className="grid grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Gender
                  </label>
                  <select
                    name="gender"
                    value={formData.gender}
                    onChange={handleInputChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    SSN
                  </label>
                  <input
                    type="text"
                    name="ssn"
                    value={formData.ssn}
                    onChange={handleInputChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              {/* Dependents Section */}
              <div className="mt-6">
                <h3 className="text-lg font-medium text-gray-900 mb-4">
                  Dependents
                </h3>
                {dependents.map((dependent, index) => (
                  <div key={index} className="grid grid-cols-2 gap-6 mb-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        First Name
                      </label>
                      <input
                        type="text"
                        value={dependent.firstName}
                        onChange={(e) =>
                          handleDependentChange(
                            index,
                            "firstName",
                            e.target.value
                          )
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Last Name
                      </label>
                      <input
                        type="text"
                        value={dependent.lastName}
                        onChange={(e) =>
                          handleDependentChange(
                            index,
                            "lastName",
                            e.target.value
                          )
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>
                ))}
                <OutlineButton onClick={addDependent}>
                  + Add Dependent
                </OutlineButton>
              </div>

              {/* Get Benefits Button */}
              <div className="mt-6">
                <OutlineButton
                  onClick={handleGetBenefits}
                  loading={loading}
                  disabled={loading}
                >
                  Get Benefits
                </OutlineButton>
              </div>

              {/* Benefits Display Section */}
              {currentBenefits && (
                <div className="mt-8 bg-white shadow rounded-lg">
                  <div className="px-6 py-4 border-b border-gray-200">
                    <h3 className="text-lg font-medium text-gray-900">
                      Benefits Information
                    </h3>
                    <p className="text-sm text-gray-500">
                      Last Fetched:{" "}
                      {new Date(currentBenefits.lastFetched).toLocaleString()}
                    </p>
                  </div>

                  {currentBenefits.errors?.length > 0 ? (
                    <div className="p-6">
                      <div className="rounded-md bg-yellow-50 p-4">
                        <div className="flex">
                          <div className="ml-3">
                            <h3 className="text-sm font-medium text-yellow-800">
                              Verification Messages
                            </h3>
                            <div className="mt-2 text-sm text-yellow-700">
                              <ul className="list-disc pl-5 space-y-1">
                                {currentBenefits.errors.map((error, index) => (
                                  <li key={index}>
                                    {error.description}
                                    {error.possibleResolutions && (
                                      <p className="text-xs mt-1 text-gray-500">
                                        Possible Resolution:{" "}
                                        {error.possibleResolutions}
                                      </p>
                                    )}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <div className="p-6">
                    {/* Provider Information */}
                    <div className="mb-8">
                      <h4 className="text-sm font-medium text-gray-900 mb-4">
                        Provider
                      </h4>
                      <dl className="grid grid-cols-2 gap-4">
                        <div>
                          <dt className="text-sm font-medium text-gray-500">
                            Organization Name
                          </dt>
                          <dd className="text-sm text-gray-900">
                            {currentBenefits.raw?.provider?.providerName ||
                              currentBenefits.provider?.organizationName ||
                              "N/A"}
                          </dd>
                        </div>
                        <div>
                          <dt className="text-sm font-medium text-gray-500">
                            NPI
                          </dt>
                          <dd className="text-sm text-gray-900">
                            {currentBenefits.raw?.provider?.npi ||
                              currentBenefits.provider?.npi ||
                              "N/A"}
                          </dd>
                        </div>
                      </dl>
                    </div>

                    {/* Payer Information */}
                    <div className="mb-8">
                      <h4 className="text-sm font-medium text-gray-900 mb-4">
                        Payer Information
                      </h4>
                      <dl className="grid grid-cols-2 gap-4">
                        <div>
                          <dt className="text-sm font-medium text-gray-500">
                            Payer Name
                          </dt>
                          <dd className="text-sm text-gray-900">
                            {currentBenefits.raw?.payer?.name ||
                              currentBenefits.payerInformation?.name ||
                              "N/A"}
                          </dd>
                        </div>
                        <div>
                          <dt className="text-sm font-medium text-gray-500">
                            Payer ID
                          </dt>
                          <dd className="text-sm text-gray-900">
                            {currentBenefits.raw?.payer?.payorIdentification ||
                              currentBenefits.payerInformation?.payerId ||
                              "N/A"}
                          </dd>
                        </div>
                      </dl>
                    </div>

                    {/* Subscriber Information */}
                    <div className="mb-8">
                      <h4 className="text-sm font-medium text-gray-900 mb-4">
                        Subscriber Information
                      </h4>
                      <dl className="grid grid-cols-2 gap-4">
                        <div>
                          <dt className="text-sm font-medium text-gray-500">
                            Member ID
                          </dt>
                          <dd className="text-sm text-gray-900">
                            {currentBenefits.raw?.subscriber?.memberId ||
                              currentBenefits.subscriber?.memberId ||
                              "N/A"}
                          </dd>
                        </div>
                        <div>
                          <dt className="text-sm font-medium text-gray-500">
                            Name
                          </dt>
                          <dd className="text-sm text-gray-900">
                            {currentBenefits.subscriber?.firstName &&
                            currentBenefits.subscriber?.lastName
                              ? `${currentBenefits.subscriber.firstName} ${currentBenefits.subscriber.lastName}`
                              : "N/A"}
                          </dd>
                        </div>
                        <div>
                          <dt className="text-sm font-medium text-gray-500">
                            Date of Birth
                          </dt>
                          <dd className="text-sm text-gray-900">
                            {currentBenefits.raw?.subscriber?.dateOfBirth
                              ? new Date(
                                  currentBenefits.raw.subscriber.dateOfBirth.replace(
                                    /(\d{4})(\d{2})(\d{2})/,
                                    "$1-$2-$3"
                                  )
                                ).toLocaleDateString()
                              : currentBenefits.subscriber?.dob
                                ? new Date(
                                    currentBenefits.subscriber.dob.replace(
                                      /(\d{4})(\d{2})(\d{2})/,
                                      "$1-$2-$3"
                                    )
                                  ).toLocaleDateString()
                                : "N/A"}
                          </dd>
                        </div>
                      </dl>
                    </div>

                    {/* Meta Information */}
                    <div className="mb-8">
                      <h4 className="text-sm font-medium text-gray-900 mb-4">
                        Transaction Details
                      </h4>
                      <dl className="grid grid-cols-2 gap-4">
                        <div>
                          <dt className="text-sm font-medium text-gray-500">
                            Trace ID
                          </dt>
                          <dd className="text-sm text-gray-900">
                            {currentBenefits.raw?.meta?.traceId ||
                              currentBenefits.meta?.traceId ||
                              "N/A"}
                          </dd>
                        </div>
                        <div>
                          <dt className="text-sm font-medium text-gray-500">
                            Mode
                          </dt>
                          <dd className="text-sm text-gray-900">
                            {currentBenefits.raw?.meta?.applicationMode ||
                              currentBenefits.meta?.applicationMode ||
                              "N/A"}
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  })
);

export default InstantVOB;
