export async function createProcessingHistory(payload) {
  try {
    console.log("Creating processing history with payload:", payload);
    const response = await store.api.post("/processing-histories", payload);
    console.log("Processing history creation response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error creating processing history:", error);
    throw error;
  }
}

export async function fetchProcessingHistories() {
  try {
    const response = await store.api.get("/processing-histories");
    return response.data;
  } catch (error) {
    console.error("Error fetching processing histories:", error);
    throw error;
  }
}

export async function updateProcessingHistory(processingHistoryId, payload) {
  try {
    const response = await store.api.put(
      `/processing-histories/${processingHistoryId}`,
      payload
    );
    return response.data;
  } catch (error) {
    console.error("Error updating processing history:", error);
    throw error;
  }
}

export async function deleteProcessingHistory(processingHistoryId) {
  try {
    const response = await store.api.delete(
      `/processing-histories/${processingHistoryId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting processing history:", error);
    throw error;
  }
}
