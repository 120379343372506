import { inject, observer } from "mobx-react";
import { Suspense, lazy, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Loader from "../../components/Loader";
import { BookOpenIcon, MenuIcon, PlusIcon } from "@heroicons/react/outline";
import HistoryPanel from "./components/HistoryPanel";
import RecordingComponent from "./components/RecordingComponent";
import InteractionPanel from "./components/InteractionPanel";
import Modal from "../../components/Modal";
import styled from "styled-components";

const Output = lazy(() => import("./components/Output"));

// Mobile navbar component that appears only on small screens
const MobileNavbar = ({ onToggleHistoryPanel, onAddClient }) => {
  return (
    <MobileNavContainer>
      <NavButton onClick={onToggleHistoryPanel}>
        <MenuIcon className="h-6 w-6" />
      </NavButton>
      <NavButton onClick={onAddClient}>
        <PlusIcon className="h-6 w-6" />
      </NavButton>
    </MobileNavContainer>
  );
};

const NavixScribe = inject("store")(
  observer(({ store, navixScribeV2Store, location }) => {
    const [loading, setLoading] = useState(false);
    const [threadId, setThreadId] = useState(null);
    const [, forceUpdate] = useState();
    const [isHistoryPanelCollapsed, setIsHistoryPanelCollapsed] =
      useState(false);
    const [showModal, setShowModal] = useState(false);
    const [videoSrc, setVideoSrc] = useState(
      "https://www.youtube.com/embed/2ugYps6mWdY"
    );
    const [isClientModalOpen, setIsClientModalOpen] = useState(false);

    const formattedName = `${store.profile.fname.charAt(0).toUpperCase() + store.profile.fname.slice(1)}${
      store.profile.lname
        ? ` ${store.profile.lname.charAt(0).toUpperCase() + store.profile.lname.slice(1)}`
        : ""
    }`;

    // useEffect(() => {
    //   if (!store.hasCurrentToolAccess()) {
    //     window.location.href = "/";
    //   }

    //   navixScribeV2Store.setTool(store.getToolByUrl(location.pathname));
    //   return () => {
    //     navixScribeV2Store.clear();
    //   };
    // }, []);

    useEffect(() => {
      forceUpdate({});
    }, [navixScribeV2Store.selectedThread]);

    useEffect(() => {
      const checkFirstInteraction = async () => {
        try {
          const histories = await navixScribeV2Store.fetchThreads();
          if (histories.length === 0) {
            // This is the first time the user is using the tool
            // Show the guide modal
            setShowModal(true);
          }
        } catch (error) {
          console.error("Error checking first interaction:", error);
        }
      };

      checkFirstInteraction();
    }, []);

    const handleHistoryPanelToggle = (isCollapsed) => {
      setIsHistoryPanelCollapsed(isCollapsed);
    };

    const closeModal = () => {
      setShowModal(false);
      setVideoSrc("");
    };

    const handleAddClient = () => {
      setIsClientModalOpen(true);
    };

    return (
      <div>
        <Helmet>
          <title>NavixScribe V2 - NavixAI</title>
        </Helmet>

        <div className="flex flex-col h-screen">
          {/* Mobile navbar - only visible on small screens */}
          <MobileNavbar
            onToggleHistoryPanel={() =>
              setIsHistoryPanelCollapsed(!isHistoryPanelCollapsed)
            }
            onAddClient={handleAddClient}
          />

          <div className="flex flex-grow relative">
            {/* History panel with backdrop for mobile */}
            <MobileBackdrop
              isVisible={!isHistoryPanelCollapsed}
              onClick={() => setIsHistoryPanelCollapsed(true)}
            />

            <HistoryPanel
              isCollapsed={isHistoryPanelCollapsed}
              onToggleCollapse={handleHistoryPanelToggle}
              onOpenClientModal={handleAddClient}
              isClientModalOpen={isClientModalOpen}
              onCloseClientModal={() => setIsClientModalOpen(false)}
            />

            <MainContent isHistoryPanelCollapsed={isHistoryPanelCollapsed}>
              <div className="flex-grow overflow-y-auto p-4 bg-white rounded-md shadow-lg">
                {navixScribeV2Store.selectedThread ? (
                  <Suspense
                    fallback={
                      <div className="flex justify-center items-center align-middle h-full">
                        <div>
                          <Loader active={true} className="w-36 h-36" />
                          <p className="text-xs text-gray-500">
                            Please wait for a moment...
                          </p>
                        </div>
                      </div>
                    }
                  >
                    <Output loading={loading} />
                  </Suspense>
                ) : (
                  <div className="flex flex-col items-center justify-center h-full">
                    <h1
                      className="text-violet-900 mb-4 font-bold text-center"
                      style={{ fontSize: "clamp(2rem, 6vw, 3.75rem)" }}
                    >
                      Hi, {formattedName}!
                    </h1>
                    <p
                      className="text-gray-500 mb-8 text-center"
                      style={{ fontSize: "clamp(1.25rem, 4vw, 1.875rem)" }}
                    >
                      Hit record and let the magic begin.
                    </p>
                    <RecordingComponent
                      navixScribeV2Store={navixScribeV2Store}
                    />
                  </div>
                )}
              </div>

              <div className="sticky bottom-0 p-4 border-t z-10 bg-white">
                <InteractionPanel navixScribeV2Store={navixScribeV2Store} />
              </div>
            </MainContent>
          </div>
        </div>

        <Modal open={showModal} onClose={closeModal} variant="video">
          <div className="flex items-center mb-2">
            <BookOpenIcon className="h-6 w-6 mr-2 text-gray-800" />
            <h2 className="text-xl font-bold">Guide</h2>
          </div>
          <p className="mb-2">
            Learn how to get started with NavixScribe V2 by watching this video:
          </p>
          <div
            className="relative w-full"
            style={{
              paddingBottom: "56.25%",
              height: 0,
              overflow: "hidden",
              maxWidth: "100%",
              background: "#000",
            }}
          >
            <iframe
              src={videoSrc}
              frameBorder="0"
              allowFullScreen
              title="NavixScribe V2 guide"
              className="absolute top-0 left-0 w-full h-full"
              style={{ border: 0 }}
            ></iframe>
          </div>
        </Modal>
      </div>
    );
  })
);

// Styled components for mobile responsiveness
const MobileNavContainer = styled.div`
  display: none;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1rem;
  background-color: #f8f8f8;
  border-bottom: 1px solid #e5e7eb;

  @media (max-width: 768px) {
    display: flex;
  }
`;

const NavButton = styled.button`
  background: none;
  border: none;
  color: #5c4bdb;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 0.375rem;

  &:hover {
    background-color: rgba(92, 75, 219, 0.1);
  }
`;

const MobileBackdrop = styled.div`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 40;

  @media (max-width: 768px) {
    display: ${(props) => (props.isVisible ? "block" : "none")};
  }
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  transition: all 0.3s ease;

  @media (min-width: 769px) {
    margin-left: ${(props) =>
      props.isHistoryPanelCollapsed ? "90px" : "300px"};
  }

  @media (max-width: 768px) {
    margin-left: 0;
    width: 100%;
  }
`;

export default NavixScribe;
