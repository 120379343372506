import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { vobStore } from "./VOBStore";
import VOBTable from "./components/VOBTable";
import Button from "../../components/Button";
import OutlineButton from "./components/OutlineButton";
import Modal from "../../components/Modal";
import toast from "react-hot-toast";
import { inject } from "mobx-react";

const VOB = inject("store")(
  observer(({ store }) => {
    const history = useHistory();
    const [showModal, setShowModal] = useState(false);
    const [profileData, setProfileData] = useState({
      vobLocation: "",
      vobNpi: "",
    });

    useEffect(() => {
      vobStore.fetchVOBs();
    }, []);

    useEffect(() => {
      const checkFirstInteraction = async () => {
        if (store.profile && !store.profile.firstVOBInteraction) {
          setShowModal(true);
        }
      };
      checkFirstInteraction();
    }, [store.profile]);

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setProfileData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSaveProfile = async () => {
      try {
        if (!profileData.vobLocation || !profileData.vobNpi) {
          toast.error("Please fill in all required fields");
          return;
        }

        await store.api.patch(`/user/${store.profile._id}`, {
          firstVOBInteraction: true,
          vobLocation: profileData.vobLocation,
          vobNpi: profileData.vobNpi,
        });

        store.profile.firstVOBInteraction = true;
        store.profile.vobLocation = profileData.vobLocation;
        store.profile.vobNpi = profileData.vobNpi;

        setShowModal(false);
        toast.success("Profile updated successfully");
      } catch (error) {
        toast.error("Failed to update profile");
      }
    };

    return (
      <div className="flex flex-col flex-1 overflow-hidden">
        <div className="flex-1 relative">
          <div className="absolute inset-0 overflow-auto">
            <div className="p-6">
              <div className="flex justify-between items-center mb-6">
                <h1 className="text-xl font-semibold text-gray-900">
                  Verification of Benefits
                </h1>
                <OutlineButton
                  size="sm"
                  onClick={() => history.push("/ai/vob/create")}
                >
                  + VOB
                </OutlineButton>
              </div>

              <div className="bg-white rounded-lg shadow overflow-hidden">
                <VOBTable />
              </div>
            </div>
          </div>
        </div>

        <Modal open={showModal} onClose={() => {}} variant="form">
          <div className="p-6">
            <h2 className="text-xl font-bold mb-4">
              Complete Your VOB Profile
            </h2>
            <p className="text-gray-600 mb-6">
              Please complete your profile to get started with Verification of
              Benefits. This information will be pre-filled in your future VOB
              requests.
            </p>

            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Location *
                </label>
                <input
                  type="text"
                  name="vobLocation"
                  value={profileData.vobLocation}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-violet-500"
                  placeholder="Enter your location"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  NPI *
                </label>
                <input
                  type="text"
                  name="vobNpi"
                  value={profileData.vobNpi}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-violet-500"
                  placeholder="Enter your NPI"
                  required
                />
              </div>
            </div>

            <div className="mt-6">
              <Button onClick={handleSaveProfile} className="w-full">
                Save Profile
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  })
);

export default VOB;
