import React, { useEffect, useRef, useState, useCallback } from "react";
import { inject, observer } from "mobx-react";
import { getRecording } from "../core/NavixScribeV2/api.service.v2";

const StreamingPlayer = inject("navixScribeV2Store")(
  observer(({ navixScribeV2Store, onReset }) => {
    const [chunks, setChunks] = useState([]);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [currentTime, setCurrentTime] = useState(0);
    const [totalDuration, setTotalDuration] = useState(0);
    const [currentChunkIndex, setCurrentChunkIndex] = useState(0);
    const [title, setTitle] = useState("");
    const audioRef = useRef(new Audio());
    const progressRef = useRef(null);
    const chunksRef = useRef([]);
    const isLoadingRef = useRef(false);
    const preloadAudioRef = useRef(new Audio());
    const preloadedChunkIndex = useRef(null);
    const [isTransitioning, setIsTransitioning] = useState(false);
    const lastTimeRef = useRef(0);
    const TRANSITION_BUFFER = 0.2;

    const fetchRecording = async () => {
      try {
        setIsLoading(true);
        const data = await getRecording(navixScribeV2Store.currentRecordingId);

        if (data.success && data.recording.chunks.length > 0) {
          const sortedChunks = data.recording.chunks.sort(
            (a, b) => a.index - b.index
          );
          setChunks(sortedChunks);
          chunksRef.current = sortedChunks;

          const total = sortedChunks.length * 5;
          setTotalDuration(total);
          setTitle(data.recording.title ?? "Untitled Recording");
          audioRef.current.src = sortedChunks[0].url;
          await audioRef.current.load();
          if (sortedChunks.length > 1) {
            await preloadNextChunk(1);
          }
        }
      } catch (error) {
        console.error("Error fetching recording:", error);
      } finally {
        isLoadingRef.current = false;
        setIsLoading(false);
      }
    };

    useEffect(() => {
      console.log("Fetching recording:", navixScribeV2Store.currentRecordingId);
      if (navixScribeV2Store.currentRecordingId && !isLoadingRef.current) {
        isLoadingRef.current = true;
        fetchRecording();
      }

      return () => {
        audioRef.current.pause();
        audioRef.current.src = "";
      };
    }, [navixScribeV2Store.currentRecordingId]);

    const preloadNextChunk = useCallback(async (nextIndex) => {
      if (nextIndex >= 0 && nextIndex < chunksRef.current.length) {
        try {
          preloadAudioRef.current.src = chunksRef.current[nextIndex].url;
          await preloadAudioRef.current.load();
          preloadedChunkIndex.current = nextIndex;
        } catch (error) {
          console.error("Error preloading chunk:", error);
        }
      }
    }, []);

    const loadChunk = useCallback(
      async (index) => {
        if (index >= 0 && index < chunksRef.current.length) {
          try {
            setIsTransitioning(true);
            const previousChunkTime = index > 0 ? 5 : 0;
            lastTimeRef.current =
              index * 5 - (index > 0 ? TRANSITION_BUFFER : 0);

            if (preloadedChunkIndex.current === index) {
              const tempSrc = audioRef.current.src;
              audioRef.current.src = preloadAudioRef.current.src;
              preloadAudioRef.current.src = tempSrc;
            } else {
              audioRef.current.src = chunksRef.current[index].url;
              await audioRef.current.load();
            }

            setCurrentChunkIndex(index);
            preloadNextChunk(index + 1);

            if (isPlaying) {
              await audioRef.current.play();
              if (index > 0) {
                audioRef.current.currentTime = 0;
              }
            }
          } catch (error) {
            console.error("Error loading chunk:", error);
            setIsPlaying(false);
          } finally {
            setIsTransitioning(false);
          }
        } else {
          audioRef.current.src = chunksRef.current[0].url;
          await audioRef.current.load();
          setCurrentChunkIndex(0);
          setIsPlaying(false);
        }
      },
      [isPlaying, preloadNextChunk, currentChunkIndex]
    );

    useEffect(() => {
      const audio = audioRef.current;

      const handleTimeUpdate = () => {
        if (isTransitioning) {
          setCurrentTime(lastTimeRef.current);
        } else {
          const chunkTime = audio.currentTime;
          const globalTime = currentChunkIndex * 5 + chunkTime;
          setCurrentTime(globalTime);
          lastTimeRef.current = globalTime;

          if (
            chunkTime >= 5 - TRANSITION_BUFFER &&
            currentChunkIndex < chunksRef.current.length - 1
          ) {
            handleEnded();
          }
        }
      };

      const handleEnded = async () => {
        if (
          currentChunkIndex < chunksRef.current.length - 1 &&
          !isTransitioning
        ) {
          const nextIndex = currentChunkIndex + 1;
          await loadChunk(nextIndex);
        } else if (currentChunkIndex >= chunksRef.current.length - 1) {
          setIsPlaying(false);
          setCurrentTime(0);
          await loadChunk(chunksRef.current.length);
        }
      };

      const handleError = (e) => {
        console.error("Audio error:", e);
        console.error("Audio element state:", {
          src: audioRef.current.src,
          networkState: audioRef.current.networkState,
          readyState: audioRef.current.readyState,
        });
        loadChunk(currentChunkIndex);
      };

      audio.addEventListener("timeupdate", handleTimeUpdate);
      audio.addEventListener("ended", handleEnded);
      audio.addEventListener("error", handleError);

      return () => {
        audio.removeEventListener("timeupdate", handleTimeUpdate);
        audio.removeEventListener("ended", handleEnded);
        audio.removeEventListener("error", handleError);
      };
    }, [currentChunkIndex, loadChunk, isTransitioning]);

    const handlePlayPause = async () => {
      try {
        if (isPlaying) {
          audioRef.current.pause();
        } else {
          if (currentTime >= totalDuration) {
            await loadChunk(0);
          }
          await audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
      } catch (error) {
        console.error("Error playing/pausing:", error);
      }
    };

    const handleSeek = async (e) => {
      const clickPosition = e.nativeEvent.offsetX;
      const progressBarWidth = progressRef.current.offsetWidth;
      const seekPercentage = clickPosition / progressBarWidth;
      const seekTime = seekPercentage * totalDuration;

      const newChunkIndex = Math.floor(seekTime / 5);
      const chunkTime = seekTime % 5;

      try {
        lastTimeRef.current = seekTime;
        await loadChunk(newChunkIndex);
        audioRef.current.currentTime = chunkTime;
        setCurrentTime(seekTime);
      } catch (error) {
        console.error("Error seeking:", error);
      }
    };

    const handleReset = () => {
      audioRef.current.pause();
      setIsPlaying(false);
      onReset?.();
    };

    const formatTime = (seconds) => {
      const mins = Math.floor(seconds / 60);
      const secs = Math.floor(seconds % 60);
      return `${mins}:${secs.toString().padStart(2, "0")}`;
    };

    if (isLoading) {
      return <div className="text-center py-4">Loading recording...</div>;
    }

    return (
      <div className="w-full max-w-2xl bg-white rounded-lg shadow-md p-4 md:p-6 mt-4 mb-4">
        <div
          ref={progressRef}
          className="w-full h-2 bg-gray-200 rounded-full mb-4 cursor-pointer relative group"
          onClick={handleSeek}
        >
          <div
            className="h-full bg-violet-600 rounded-full"
            style={{ width: `${(currentTime / totalDuration) * 100}%` }}
          />
        </div>

        <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4">
          <div className="flex items-center gap-4">
            <button
              onClick={handlePlayPause}
              className="p-3 rounded-full bg-violet-600 text-white hover:bg-violet-700 transition-colors"
              disabled={chunks.length === 0}
            >
              {isPlaying ? (
                <PauseIcon className="w-6 h-6" />
              ) : (
                <PlayIcon className="w-6 h-6" />
              )}
            </button>

            <div className="text-sm text-gray-600">
              {formatTime(currentTime)} / {formatTime(totalDuration)}
            </div>
          </div>

          <div className="flex flex-col md:flex-row md:items-center gap-3 md:gap-4">
            <div className="text-sm text-gray-500 truncate max-w-[200px]">
              {title}
            </div>

            <button
              onClick={handleReset}
              className="px-4 py-2 bg-gray-200 text-gray-700 rounded-lg hover:bg-gray-300 transition-colors w-full md:w-auto"
            >
              Start New Recording
            </button>
          </div>
        </div>
      </div>
    );
  })
);

const PlayIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    className={className}
  >
    <path
      fillRule="evenodd"
      d="M4.5 5.653c0-1.426 1.529-2.33 2.779-1.643l11.54 6.348c1.295.712 1.295 2.573 0 3.285L7.28 19.991c-1.25.687-2.779-.217-2.779-1.643V5.653z"
      clipRule="evenodd"
    />
  </svg>
);

const PauseIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    className={className}
  >
    <path
      fillRule="evenodd"
      d="M6.75 5.25a.75.75 0 01.75-.75H9a.75.75 0 01.75.75v13.5a.75.75 0 01-.75.75H7.5a.75.75 0 01-.75-.75V5.25zm7.5 0A.75.75 0 0115 4.5h1.5a.75.75 0 01.75.75v13.5a.75.75 0 01-.75.75H15a.75.75 0 01-.75-.75V5.25z"
      clipRule="evenodd"
    />
  </svg>
);

export default StreamingPlayer;
