import React, { useEffect, useRef, useState } from "react";
import { inject, observer } from "mobx-react";
import WaveSurferRecorder from "../../../components/WaveSurferRecorderV2";
import { toast } from "react-hot-toast";
import Loader from "../../../components/Loader";
import styled from "styled-components";
import { UploadIcon } from "@heroicons/react/outline";
import {
  transcribeAudio,
  checkAccountStatus,
  updateHistory,
  waitForTranscription,
  streamAudioChunk,
} from "../api.service.v2";
import { v4 as uuid } from "uuid";
import { formatDuration } from "../../../tools/audio/audioUtils";
import { createThread, addMessage } from "../azure-api-service";
import StreamingPlayer from "../../../components/StreamingPlayer";
import TranscriptionLoader from "./TranscriptionLoader";
import RecordedDraftsModal from "../../../components/RecordedDraftsModal";
import moment from "moment";

const AUDIO_ACCEPT_TYPES = [
  "audio/mpeg",
  "audio/mp3",
  "audio/wav",
  "audio/aac",
  "audio/flac",
  "audio/ogg",
  "audio/x-ms-wma",
  "audio/x-m4a",
  "audio/aiff",
  "audio/x-aac",
  "audio/mp4",
  "audio/vnd.dlna.adts",
];

const RecordingComponent = inject(
  "navixScribeV2Store",
  "store"
)(
  observer(({ navixScribeV2Store, store, onClose, selectedClient }) => {
    const [loading, setLoading] = useState(false);
    const [audioUrl, setAudioUrl] = useState(null);
    const [isRecording, setIsRecording] = useState(false);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [isTranscribing, setIsTranscribing] = useState(false);
    const [streamingError, setStreamingError] = useState(null);
    const audioComponentRef = useRef(null);
    const [showDraftsModal, setShowDraftsModal] = useState(false);
    const transcriptionStage = store.transcriptionStage;

    useEffect(() => {
      if (navixScribeV2Store.inputActiveRecord.subToolAction === "audio") {
        if (navixScribeV2Store.inputActiveRecord.audioTranscriber) {
          setAudioUrl(
            navixScribeV2Store.inputActiveRecord.audioTranscriber?.aws_url
          );
        }
      }
    }, [navixScribeV2Store.inputActiveRecord]);

    const clearAudioChunks = () => {
      // const current = audioComponentRef.current;
      // if (current) {
      //   current.clearAudioChunks();
      // }
    };

    const handleStartRecording = async () => {
      try {
        navixScribeV2Store.resetRecording();
        navixScribeV2Store.setRecordingStatus("recording");

        const newRecordingId =
          await audioComponentRef.current?.startRecording();
        if (newRecordingId) {
          navixScribeV2Store.startNewRecording(newRecordingId);
        }
        navixScribeV2Store.startRecording();
      } catch (error) {
        console.error("Failed to start recording:", error);
        setStreamingError("Failed to start recording");
      }
    };

    const handleStopRecording = async () => {
      try {
        await audioComponentRef.current?.stopRecording();
        navixScribeV2Store.setRecordingStatus("completed");
        navixScribeV2Store.stopRecording();
      } catch (error) {
        console.error("Failed to stop recording:", error);
        setStreamingError("Failed to stop recording");
      }
    };
    const handleAudioChunk = async (chunk, isFinal, metadata) => {
      console.log("Received chunk:", { chunk, isFinal, metadata }); // Debug log
      if (!metadata.recordingId) return;

      if (!navixScribeV2Store.currentRecordingId) {
        navixScribeV2Store.setCurrentRecordingId(metadata.recordingId);
      }

      const formData = new FormData();
      formData.append("chunk", chunk);
      formData.append("recordingId", metadata.recordingId);
      formData.append("chunkNumber", metadata.chunkNumber);
      formData.append("timestamp", metadata.timestamp);
      formData.append("duration", metadata.duration);
      formData.append("isFinal", isFinal.toString());

      try {
        console.log("Sending chunk to backend..."); // Debug log
        const response = await streamAudioChunk(formData);
        console.log("Backend response:", response); // Debug log

        if (isFinal) {
          navixScribeV2Store.setRecordingStatus("completed");
          toast.success("Recording completed successfully");
        }
      } catch (error) {
        console.error("Error uploading chunk:", error);
        setStreamingError(error.message);
        navixScribeV2Store.addFailedChunk({
          chunk,
          metadata,
          error: error.message,
        });
        toast.error("Error uploading audio chunk");
      }
    };

    const handleAudioUrl = (url) => {
      setAudioUrl(url);
    };

    const handleBlob = (blob) => {
      navixScribeV2Store.setInputForm("file", blob);
      navixScribeV2Store.setInputForm("filename", "");
    };

    const handleDraftSelected = (draft) => {
      // Set the recording ID and status in the store
      navixScribeV2Store.setCurrentRecordingId(draft.recordingId);
      if (draft.chunks.length > 0) {
        navixScribeV2Store.setAudioDuration(draft.totalDuration);
      }
      navixScribeV2Store.setRecordingStatus("completed");
    };

    useEffect(() => {
      return () => {
        if (audioUrl) {
          URL.revokeObjectURL(audioUrl);
        }
      };
    }, [audioUrl]);

    const handleTranscribe = async () => {
      const {
        inputForm: { file },
        setInputForm,
        setActiveHistory,
        setTriggerSection,
      } = navixScribeV2Store;

      clearAudioChunks();

      try {
        setIsTranscribing(true);
        const shouldCreateNewThread = !navixScribeV2Store.selectedThread;

        const checkAccountStatusResult = await checkAccountStatus();
        if (!checkAccountStatusResult.success) {
          toast.error(checkAccountStatusResult.message);
          return;
        }

        store.setTranscriptionStage("uploading");
        setLoading(true);

        // Calculate duration based on the source type
        let duration, durationString;
        if (navixScribeV2Store.currentRecordingId) {
          duration = Math.floor(navixScribeV2Store.savedDuration / 1000);
          durationString = formatDuration(duration);
        } else if (file) {
          duration = Math.floor(navixScribeV2Store.savedDuration / 1000 - 1);
          durationString = formatDuration(duration);
        } else {
          throw new Error("No valid audio source found");
        }

        let result;

        // Handle chunked recording
        if (
          navixScribeV2Store.currentRecordingId &&
          navixScribeV2Store.recordingStatus === "completed"
        ) {
          const clientData = selectedClient
            ? {
                _id: selectedClient._id,
                name: selectedClient.name,
                status: selectedClient.status,
              }
            : navixScribeV2Store.selectedClient
              ? {
                  _id: navixScribeV2Store.selectedClient._id,
                  name: navixScribeV2Store.selectedClient.name,
                  status: navixScribeV2Store.selectedClient.status,
                }
              : null;

          result = await transcribeAudio(null, duration, durationString, {
            isChunked: true,
            recordingId: navixScribeV2Store.currentRecordingId,
            assignedToClient: clientData,
          });
        } else if (file instanceof Blob) {
          const clientData = selectedClient
            ? {
                _id: selectedClient._id,
                name: selectedClient.name,
                status: selectedClient.status,
              }
            : navixScribeV2Store.selectedClient
              ? {
                  _id: navixScribeV2Store.selectedClient._id,
                  name: navixScribeV2Store.selectedClient.name,
                  status: navixScribeV2Store.selectedClient.status,
                }
              : null;

          console.log("Sending transcribe request with client:", clientData);

          result = await transcribeAudio(file, duration, durationString, {
            assignedToClient: clientData,
          });
        } else {
          throw new Error("No valid audio source found");
        }

        store.setTranscriptionStage("processing");

        console.log("Result:", result);

        if (result.data.history && result.data.history._id) {
          setActiveHistory(result.data.history._id, "Input");
          navixScribeV2Store.startTranscriptionTracking(
            result.data.history._id,
            shouldCreateNewThread
          );

          // Emit processing history created event with the ID from the response
          if (result.data.processingHistoryId) {
            navixScribeV2Store.emitProcessingHistoryCreated(
              result.data.processingHistoryId
            );
          }
        }
      } catch (error) {
        console.error("Error in handleTranscribe:", error);
        toast.error("Error during transcription");
      } finally {
        setLoading(false);
        setTriggerSection("Input");
        navixScribeV2Store.setTranscriptionShouldCreateNewThread(false);
      }
    };

    const handleFileChangeEvent = async (e) => {
      const file = e.target.files[0];
      const { setInputForm } = navixScribeV2Store;
      if (!file) return;

      try {
        const MAX_FILE_SIZE_MB = 100;
        const sizeInMb = file.size / 1024 / 1024;
        if (sizeInMb > MAX_FILE_SIZE_MB) {
          toast.error("File size must be less than or equal to 100MB");
          return;
        }

        if (AUDIO_ACCEPT_TYPES.includes(file.type)) {
          setUploadedFile(file);
          setInputForm("file", file);
          setInputForm("filename", file.name);
          // Reset recording state when uploading a file
          navixScribeV2Store.resetRecording();
        } else {
          throw new Error("Unsupported file type");
        }
      } catch (err) {
        toast.error("Error processing file: " + err.message);
        setInputForm("file", null);
        setInputForm("filename", "");
        setUploadedFile(null);
      }
    };

    const isGenerateButtonDisabled = () => {
      const {
        inputForm: { file },
        currentRecordingId,
        recordingStatus,
      } = navixScribeV2Store;

      // Disable if loading or transcribing
      if (loading || isTranscribing) {
        return true;
      }

      // Enable if we have a completed chunked recording
      if (currentRecordingId && recordingStatus === "completed") {
        return false;
      }

      // Enable if we have a regular file uploaded
      if (file instanceof Blob) {
        return false;
      }

      // Disable in all other cases
      return true;
    };

    const handleClearUpload = () => {
      setUploadedFile(null);
      navixScribeV2Store.setInputForm("file", null);
      navixScribeV2Store.setInputForm("filename", "");
    };

    useEffect(() => {
      const handleStateReset = () => {
        setLoading(false);
        setAudioUrl(null);
        setIsRecording(false);
        setUploadedFile(null);
        setIsTranscribing(false);
        setStreamingError(null);
        setShowDraftsModal(false);
        store.setTranscriptionStage("idle");
      };

      navixScribeV2Store.on("stateReset", handleStateReset);

      return () => {
        navixScribeV2Store.off("stateReset", handleStateReset);
      };
    }, [navixScribeV2Store]);

    return (
      <OuterContainer>
        <RecorderContainer>
          {isTranscribing ? (
            <TranscriptionLoader stage={transcriptionStage} />
          ) : (
            <>
              <div className="flex flex-col items-center justify-center p-4">
                {streamingError && (
                  <div className="text-red-500 text-sm mb-2">
                    Error: {streamingError}
                  </div>
                )}

                <WaveSurferRecorder
                  ref={audioComponentRef}
                  onAudioUrl={handleAudioUrl}
                  onBlob={handleBlob}
                  onAudioChunk={handleAudioChunk}
                  onStartRecording={handleStartRecording}
                  onStopRecording={handleStopRecording}
                  uploadedFile={uploadedFile}
                  initialRecordingId={navixScribeV2Store.currentRecordingId}
                  recordingStatus={navixScribeV2Store.recordingStatus}
                  onClear={handleClearUpload}
                />

                {showDraftsModal && (
                  <RecordedDraftsModal
                    onClose={() => setShowDraftsModal(false)}
                    onDraftSelected={handleDraftSelected}
                  />
                )}

                <ButtonContainer>
                  {!uploadedFile && !navixScribeV2Store.currentRecordingId && (
                    <>
                      <UploadButton htmlFor="file-upload">
                        <UploadIcon className="w-6 h-6" />
                        Upload Audio File
                      </UploadButton>
                      <button
                        onClick={() => setShowDraftsModal(true)}
                        className="text-violet-600 hover:text-violet-700"
                      >
                        Load Draft
                      </button>
                      <input
                        type="file"
                        id="file-upload"
                        className="hidden"
                        accept={AUDIO_ACCEPT_TYPES.join(",")}
                        onChange={handleFileChangeEvent}
                      />
                    </>
                  )}
                  <TranscribeButton
                    onClick={handleTranscribe}
                    disabled={isGenerateButtonDisabled()}
                  >
                    <Loader active={loading} className="w-6 h-6 mr-1" />
                    Transcribe
                  </TranscribeButton>
                </ButtonContainer>

                {loading && (
                  <div className="mt-4">
                    <span className="text-violet-600">
                      Recording in progress...
                    </span>
                  </div>
                )}
              </div>
            </>
          )}
        </RecorderContainer>
      </OuterContainer>
    );
  })
);

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  border-radius: 10px;
  background: #ffffff;
  width: 780px;
  border: 1px solid #d1d1d2; /* Add the border here */

  @media (max-width: 768px) {
    width: 100%;
    padding: 1rem;
    border-radius: 8px;
  }
`;

const RecorderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  width: 100%;
  height: 300px;

  @media (max-width: 768px) {
    height: auto;
    min-height: 250px;
    gap: 1rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between; /* Adjust this for spacing between buttons */
  gap: 1rem; /* Adjust the gap between buttons if necessary */
  width: 100%;
  margin-top: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0.75rem;
  }
`;

const UploadButton = styled.label`
  background: #f3f4f6;
  color: #000000;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0.75rem 1rem;
  }
`;

const TranscribeButton = styled.button`
  background-color: #4c1d95;
  color: white;
  padding: 0.5rem 2rem;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: background 0.3s;

  &:disabled {
    background: #b0a0c9;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background: #b39ddb;
  }

  &:active:not(:disabled) {
    background: #9575cd;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 0.75rem 1rem;
  }
`;

export default RecordingComponent;
