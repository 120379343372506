import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";

const TranscriptionLoader = ({ stage = "uploading" }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((oldProgress) => {
        if (stage !== "uploading") {
          return 100;
        }
        if (oldProgress >= 100) {
          clearInterval(interval);
          return 100;
        }
        return oldProgress + 100 / 600;
      });
    }, 100);

    return () => clearInterval(interval);
  }, [stage]);

  const getMessage = () => {
    switch (stage) {
      case "uploading":
        return {
          heading: "Uploading",
          subheading:
            "Securely uploading your audio to the cloud, please wait for a moment.",
        };
      case "Processing chunk transcription for recording":
        return {
          heading: "Processing",
          subheading: "Starting to process your audio recording...",
        };
      case "Combining chunks":
        return {
          heading: "Processing",
          subheading: "Combining audio chunks...",
        };
      case "Uploading combined file to S3":
        return {
          heading: "Processing",
          subheading: "Uploading processed audio...",
        };
      case "Uploading combined file for transcription":
        return {
          heading: "Processing",
          subheading: "Preparing for transcription...",
        };
      case "Starting Lemur transcription":
        return {
          heading: "Transcribing",
          subheading: "Starting the transcription process...",
        };
      case "Updating transcription details":
        return {
          heading: "Transcribing",
          subheading: "Transcribing your audio...",
        };
      case "Waiting for webhook":
        return {
          heading: "Creating thread",
          subheading: "Almost done! Initializing thread...",
        };
      case "Transcription completed":
        return {
          heading: "Creating thread",
          subheading: "Creating a thread for your transcription...",
        };
      case "Created thread":
        return {
          heading: "Creating thread",
          subheading: "Thread created, getting results...",
        };
      case "completed":
        return {
          heading: "Done!",
          subheading: "Your transcription is ready!",
        };
      default:
        return {
          heading: "Processing",
          subheading:
            "Processing your audio... optionally, you can leave this page and come back later.",
        };
    }
  };

  const { heading, subheading } = getMessage();

  return (
    <LoaderContainer>
      <ProgressBar progress={progress} />
      <ContentContainer>
        <SpinnerAndTextContainer>
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
          <TextContainer>
            <Heading>{heading}</Heading>
            <Subheading>{subheading}</Subheading>
          </TextContainer>
        </SpinnerAndTextContainer>
      </ContentContainer>
    </LoaderContainer>
  );
};

const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: #f4f0fa;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ProgressBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: ${(props) => props.progress}%;
  background-color: #e0d7ff;
  transition: width 0.1s linear;
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const SpinnerAndTextContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    padding: 1rem;
  }
`;

const SpinnerContainer = styled.div`
  margin-right: 20px;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 1rem;
  }
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
  width: 50px;
  height: 50px;
  border: 6px solid #4c1d95;
  border-top: 6px solid #bebebe;
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
`;

const TextContainer = styled.div`
  text-align: left;

  @media (max-width: 768px) {
    text-align: center;
    width: 100%;
  }
`;

const Heading = styled.h2`
  color: #000000;
  font-size: 14px;
  margin-bottom: 0.5rem;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const Subheading = styled.p`
  color: #000000;
  font-size: 12px;

  @media (max-width: 768px) {
    font-size: 14px;
    max-width: 100%;
  }
`;

export default TranscriptionLoader;
