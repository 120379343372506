import React from "react";
import { useHistory } from "react-router-dom";
import Button from "../../../components/Button";
import clsx from "clsx";
import InstantVOB from "./InstantVOB";
import EstimatesVOB from "./EstimatesVOB";
import LiveVOB from "./LiveVOB";
import { Tab } from "@headlessui/react";

const CreateVOB = () => {
  const history = useHistory();

  const handleBack = () => {
    history.push("/ai/vob");
  };

  const tabs = [
    { name: "Instant", component: InstantVOB },
    { name: "Estimates", component: EstimatesVOB },
    { name: "Live", component: LiveVOB },
    { name: "Planner", component: () => <div>Planner coming soon...</div> },
  ];

  return (
    <div className="flex flex-col h-full">
      <div className="p-4 border-b border-gray-200 flex justify-between items-center">
        <Button variant="secondary" onClick={handleBack}>
          Back to VOB List
        </Button>
        <h1 className="text-xl font-semibold">Add VOB</h1>
        <div className="w-24" /> {/* Spacer for alignment */}
      </div>

      <Tab.Group>
        <Tab.List className="border-b border-gray-200">
          <div className="px-6">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {tabs.map((tab) => (
                <Tab
                  key={tab.name}
                  className={({ selected }) =>
                    clsx(
                      selected
                        ? "border-indigo-500 text-indigo-600"
                        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                      "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium focus:outline-none"
                    )
                  }
                >
                  {tab.name}
                </Tab>
              ))}
            </nav>
          </div>
        </Tab.List>
        <Tab.Panels className="flex-1 overflow-auto">
          {tabs.map((tab, idx) => (
            <Tab.Panel key={idx}>
              <tab.component />
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default CreateVOB;
